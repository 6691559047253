.tapform-logo-with-label-component{
  &-holder{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 26px;
  }
  &-image{
    height: 23px;
    width: auto;
    margin-right: 10px;
  }
  &-middle{
    height: 23px;
    width: 1px;
    background-color: #718096;
    opacity: 0.5;
  }
  &-right{
    font-size: 16px;
    font-weight: 600;
    color: #718096;
    margin-left: 10px;
    white-space: nowrap;
    &.white{
      color: #fff;
      opacity: 0.6;
    }
  }
}