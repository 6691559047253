.header-component {
  &-container {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 25px;
    position: sticky;
    top: 0;
    background-color: var(--foreground-color);
    z-index: 1;

    @media screen and (max-width: 1024px) {
      padding: 0 20px;
    }

    &.walkthrough{
      z-index: 10;
    }
  }
  &-holder {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;

    &-item{
      width: 33.3333%;
      display: flex;
      align-items: center;

      &:nth-child(1){
        order: 1;
        justify-content: flex-start;
        @media screen and (max-width: 1024px){
          order: 3;
          justify-content: flex-end;
        }
      }

      &:nth-child(2){
        order: 2;
        justify-content: center;
        @media screen and (max-width: 1024px){
          order: 1;
          justify-content: flex-start;
        }
      }

      &:nth-child(3){
        order: 3;
        justify-content: flex-end;
        @media screen and (max-width: 1024px){
          display: none;
        }
      }
    }
  }
  &-copy-link {
    height: 42px;
    margin-right: 15px;

    @media screen and (max-width: 1024px){
      margin-right: 0;
      margin-bottom: 15px;
      display: none !important;
    }
  }
  &-logout{
    padding: 0 10px;
    height: 32px;
    font-size: 16px;
    font-weight: 600;
    color: var(--text-color-primary);
    opacity: 0.7;
    background-color: transparent;
    cursor: pointer;
  }
  &-nav {
    margin-right: 15px;
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
}
