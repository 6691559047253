.container{
  display: flex;
  align-items: center;
  padding: 0 15px;
  height: 41px;
  border-radius: 12px;
  width: fit-content;
  font-size: 16px;
  font-weight: 600;
  
  &.blue{
    background-color: #E0EFFF;
    color: #2c93ff;
  }
  &.red{
    background-color: #FFECEB;
    color: #ff453a;
  }
}