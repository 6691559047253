.container{
  width: 100%;
  min-height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 35px;

  @media screen and (max-width: 1024px) {
    justify-content: flex-start;
    flex-direction: column;
    padding: 30px 0px 150px;
    height: auto;
    background-color: var(--background-color);
    overflow: hidden;
  }
}