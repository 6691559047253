.input-component {
  &-container {
    position: relative;
  }
  &-input {
    padding: 15px 60px 16px 16px;
    font-size: 16px;
    font-weight: 500;
    color: var(--input-text-color);
    min-width: 260px;
    border-radius: var(--input-border-radius);
    border: solid 2px var(--input-border-color-inactive);
    transition: 0.25s ease;

    &.error {
      border-color: var(--input-border-color-error) !important;
    }

    &:hover {
      border-color: var(--input-border-color-hover);
    }

    &:focus {
      border-color: var(--input-border-color-focus);
      box-shadow: 0 0 10px 0 rgba(26, 158, 217, 0.2);

      & + .input-component-valid.active {
        opacity: 1;
      }
    }
  }
  &-valid {
    position: absolute;
    top: 20px;
    right: 20px;
    min-width: 24px;
    min-height: 24px;
    max-width: 24px;
    max-height: 24px;
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--input-border-color-focus);
    opacity: 0;
    transition: 0.25s ease-in;

    &.inactive {
      opacity: 0;
    }

    & svg {
      width: 12px;
    }
  }
}
