.container{
  width: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1024px) {
    height: 100%;
  }
}
.form{
  width: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1024px) {
    height: 100%;
    justify-content: space-between;
  }
}
.input{
  width: 100%;
  margin-bottom: 93px;

  & input {
    border-radius: 12px;
  }
}
.submit{
  width: 100%;
  min-height: 62px;
}