html {
  --blue-color: #0075ff;
  --ai-color: #9b6bff;
  --white-color: #fff;
  --text-color-primary: #0d3051;
  --text-color-secondary: #6e8397;
  --text-color-white: #ffffff;
  --input-placeholder-color: #c3c7ca;
  --input-border-color-inactive: #e5e7eb;
  --input-border-color-hover: #9ba1a7;
  --input-border-color-focus: #0075ff;
  --input-border-color-ai-focus: #7b3dfc;
  --input-shadow-color-focus: 0 0 10px 0 rgba(26, 158, 217, 0.2);
  --input-shadow-color-ai-focus: 0 0 10px 0 rgba(#8164fb, 0.2);
  --input-border-color-error: #ff3b30;
  --input-text-color: #041524;
  --input-border-radius: 12px;
  --foreground-color: #edf2f6;
  --background-color: #f6f8fa;
  --foreground-border: 1px solid #e2e5e7; 
  --foreground-background: #fff;
  --new-badge-text-color: #0070f3;
  --new-badge-background-color: #eff6ff;
  --new-badge-border-color: #daebff;
  --lead-list-border-color: #e3e3e3;
  --lead-list-avatar-color: #d8d8d8;
  --button-phone-color: #0b9f6e;
  --button-print-color: #8e9da9;
  --button-back-color: #dee6ec;
  --button-copy-link-color: #09a216;
  --answer-background-color: rgba(22, 193, 182, 0.2);
  --answer-text-color: #054533;
  --checkbox-inactive-color: #dae1e7;
  --checkbox-active-color: #0b9f6e;
}
