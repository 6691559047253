.popup-close-button-component{
  &-container{
    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #edf2f6;
    transition: var(--transition-simple-fast);
    cursor: pointer;

    &:active{
      transform: scale(0.80);
      filter: brightness(0.6);
    }
  }
  &-icon{
    width: 15px;
    height: auto;
  }
}