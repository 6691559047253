.toggler-component {
  &-container {
    display: flex;
    position: relative;
  }
  &-item {
    padding: 0 10px;
    height: 32px;
    font-size: 16px;
    font-weight: bold;
    color: var(--text-color-primary);
    background-color: transparent;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
  &-background {
    background-color: var(--white-color);
    border-radius: 8px;
    position: absolute;
    width: 0px;
    top: 0;
    left: 0;
    height: 32px;
    z-index: -1;

    &.add-transition {
      transition: var(--transition-simple);
    }
  }
}
