.tapform-form-component{
  &-container{
    width: 506px;
    height: 260px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 11px;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    overflow: hidden;
    position: relative;

    @media screen and (max-width: 1024px){
      width: min(calc(100% - 50px), 506px)
    }
  }
  &-progress{
    width: 100%;
    height: 2px;
    display: flex;
    &-bar{
      width: 60px;
      height: 2px;
      display: flex;
    }
  }
  &-header{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 6px 12px;
    margin-bottom: 18px;
    &-back{
      min-width: 13px;
      min-height: 13px;
      max-width: 13px;
      max-height: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 12px;
      top: 6px;
      &-icon{
        width: 100%;
        height: auto;
      }
    }
    &-logo{
      height: 9px;
      width: auto;
    }
    &-phone{
      display: flex;
      position: absolute;
      align-items: center;
      right: 12px;
      top: 10px;
      &-left{
        display: flex;
        flex-direction: column;
        text-align: right;
        margin-right: 5px;
        &-one{
          font-size: 5px;
          font-weight: bold;
          color: #0d3051;
        }
        &-two{
          font-size: 5px;
          font-weight: bold;
          color: #0d3051;
        }
      }
      &-action{
        min-width: 15px;
        max-width: 15px;
        min-height: 15px;
        max-height: 15px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ecf0f2;
        &-icon{
          width: 9px;
          height: auto;
        }
      }
    }
  }
  &-question{
    max-width: 180px;
    font-size: 12px;
    font-weight: bold;
    color: #0d3051;
    margin-bottom: 15px;
    min-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
  }
  &-answers{
    width: 326px;
    display: flex;
    max-width: calc(100% - 20px);

    &.single-select{
      @media screen and (max-width: 398px){
        transform: translateY(-20px) scale(0.6);
      }
    }
    &-holder{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      &.single-select-no-icon{
        flex-direction: column;
        align-items: center;
      }
    }
  }
  &-answer{
    width: calc(25% - 6.5px);
    margin: 3px;
    border-radius: 6px;
    background-color: #f3f4f4;
    overflow: hidden;
    border: 1px solid transparent;

    &.single-select-no-icon{
      min-width: 120px;
      max-width: fit-content;
      background-color: #fff;
      border-color: #f3f4f4;
      box-shadow: rgba(12, 22, 35, 0.05) 0px 5px 14px 0px
    }

    &-image{
      height: 77px;
      width: 100%;
      object-fit: cover;
    }
    &-text{
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 6px 5px 11px;
      font-size: 6px;
      font-weight: bold;
      color: #0d3051;
      &.single-select-no-icon{
        padding: 6px;
        justify-content: flex-start;
        width: 100%;
      }
    }
    &-dot{
      min-width: 6px;
      min-height: 6px;
      max-width: 6px;
      max-height: 6px;
      border-radius: 50%;
      background-color: #d1d6df;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 3px;
      &-inner{
        min-width: 4px;
        min-height: 4px;
        max-width: 4px;
        max-height: 4px;
        background-color: #fff;
      }
    }
    &:first-child{
      background-color: #e8e8e8;
      &.single-select-no-icon{
        background-color: #fff;
      }
      border-color: var(--brand-color);

      & .tapform-form-component-answer-dot{
        background-color: var(--brand-color);
        & > span {
          min-width: 2px;
          min-height: 2px;
          max-width: 2px;
          max-height: 2px;
        }
      }
    }
  }
  &-submit{
    min-width: 170px;
    padding: 0 20px;
    height: 22px;
    border-radius: 11px;
    font-size: 8px;
    font-weight: bold;
    color: #fff;

    &-holder{
      position: absolute;
      bottom: 0px;
      width: 100%;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: linear-gradient(180deg,hsla(0,0%,100%,0),#fff);
      padding: 32px 0 10px;
    }
  }
}