@keyframes fadeIn {
  0%{
    opacity: 0;
    transform: translateY(-100%);
  }
  100%{
    opacity: 1;
    transform: translateY(0%);
  }
}
.container{
  width: 100%;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 882px){
    justify-content: flex-start;
  }
  
  &.containerVisible{
    margin-bottom: 35px;
  }
}
.holder{
  width: 100%;
  max-width: 1000px;
  border-radius: 15px;
  background-image: linear-gradient(to left, #fff 100%, #fff 37%);
  display: flex;
  justify-content: space-between;
  border: 2px solid #E6EBEF;
  overflow: hidden;

  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-fill-mode: forwards;
  
  @media screen and (max-width: 882px){
    flex-direction: column-reverse;
    max-width: 335px;
  }
}
.left{
  width: 100%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  padding: 35px;
  @media screen and (max-width: 882px){
    padding: 40px 25px 35px;
  }
}
.right{
  width: 100%;
  position: relative;
  display: flex;
  @media screen and (max-width: 882px){
    height: 292px;
  }
}
.imageDesk{
  position: absolute;
  height: 722px;
  right: -77px;
  top: -262px;
  opacity: 0;
  transition: var(--transition-simple);
  &.loaded{
    opacity: 1;
  }
}
.imageMobile{
  position: absolute;
  height: 604px;
  right: -106px;
  top: -245px;
  opacity: 0;
  transition: var(--transition-simple);
  &.loaded{
    opacity: 1;
  }
}
.title{
  font-size: 22px;
  font-weight: bold;
  color: #000;
  margin-bottom: 4px;
}
.description{
  font-size: 15px;
  font-weight: 500;
  color: #718096;
  margin-bottom: 29px;

  @media screen and (max-width: 882px){
    margin-bottom: 32px;
  }
}
.button{
  display: flex;
  align-items: center;
  font-size: 17px;
  font-weight: bold;
  color: #0075ff;
  transition: var(--transition-simple);
  cursor: pointer;
  background-color: transparent;
  width: fit-content;
  & > svg path:nth-child(2){
    fill: #0075ff;
  }
  &:hover{
    transform: scale(1.04);
  }
}
.cta{
  display: flex;
  @media screen and (max-width: 882px){
    flex-direction: column;
  }
}
.ctaLabel{
  margin-right: 15px;
  @media screen and (max-width: 882px){
    margin-right: 0;
    margin-bottom: 35px;
  }
}