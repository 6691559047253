.checkbox-toggler-component {
  &-container{
    background-color: var(--checkbox-inactive-color);
    transition: var(--transition-simple);
    height: 24px;
    border-radius: 12px;
    width: 58px;
    position: relative;
    &.active{
      background-color: var(--checkbox-active-color);
    }
    cursor: pointer;
  }
  &-block{
    transition: var(--transition-simple);
    width: 30px;
    height: 18px;
    border-radius: 9px;
    position: absolute;
    top: 3px;
    left: 4px;
    background-color: var(--white-color);
    &.active{
      left: 24px;
    }
  }
}