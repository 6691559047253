.spinner-component {
  &-dot{
    width: 70px;
    text-align: center;

    &-bounce1,
    &-bounce2,
    &-bounce3{
      width: 18px;
      height: 18px;
      background-color: var(--blue-color);
    
      border-radius: 100%;
      display: inline-block;
      -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }

    &-bounce1{
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }

    &-bounce2{
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }
  }
  &-circle{
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 3px solid transparent;
    border-radius: 50%;
    border-top-color: var(--blue-color);
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
  }
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}