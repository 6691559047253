.new-badge-component {
  &-container {
    height: 24px;
    padding: 0 9.5px;
    border-radius: 12px;
    background-color: var(--new-badge-background-color);
    border: 1px solid var(--new-badge-border-color);
    color: var(--new-badge-text-color);
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    width: fit-content;
    width: -moz-fit-content;
  }
}
