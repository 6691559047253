.feedback-step {
  &-holder{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 45px 35px 35px;

    @media screen and (max-width: 1024px){
      overflow: scroll;
      max-height: 100vh;
    }
  }
  &-label {
    text-align: center;
    font-size: 17px;
    font-weight: 800;
    color: #d96824;
    margin-bottom: 8px;
  }
  &-title{
    font-size: 26px;
    font-weight: 800;
    color: #252a31;
    text-align: center;
    margin-bottom: 35px;
  }
  &-answers{
    display: flex;
    margin-bottom: 35px;
    width: 100%;
    justify-content: center;

    &.single-select{
      flex-direction: column;
    }

    &.card{
      flex-direction: row;
      flex-wrap: wrap;
    }

    &-item{
      cursor: pointer;
      transition: 0.15s ease-out;
  
      &:hover{
        transform: scale(1.03);
      } 

      &.card{
        width: calc(50% - 12.5px);
        min-height: 162px;
        max-height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 25px;
        border-radius: 10px;
        padding: 25px;
        border-style: solid;
        border-width: 2px;
        background-color: #fff;
        border-color: #DCDDDD;

        &:nth-child(2n-5){
          margin-right: 25px;
        }
      }

      &.single-select{
        width: 100%;
        padding: 28px 25px 29px;
        margin-bottom: 10px;
        border-radius: 10px;
        border: solid 2px #ddd;
        background-color: #fff;
      }

      &.active {
        border-color: #1a9ed9;
        box-shadow: 0 0 0 4.5px rgba(26, 158, 217, 0.2), inset 0 0 0 4.5px rgba(26, 158, 217, 0.2), inset 0 0 0 3px #1a9ed9;
        transform: scale(1.03);
      }

      &-icon{
        margin-bottom: 23px;
        font-size: 60px;
      }

      &-text{
        font-size: 19px;
        font-weight: 600;
        color: #252a31;

        &.card{
          text-align: center;
        }
      }
    }
  }

  &-note{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 37px;

    &-title{
      font-size: 17px;
      font-weight: 600;
      color: #252a31;
      text-align: left;
      margin-bottom: 10px;
    }

    &-input{
      border-radius: 10px;
      border: solid 2px #ddd;
      background-color: #fff;
      color: #252a31;
      padding: 15px;
      resize: none;
      height: 151px;
      font-size: 16px;
      font-weight: 500;
    }
  }

  &-submit{
    width: 100%;
    margin: 0;
    height: 62px;
    border-radius: 10px;
    border: solid 1px #e3e3e3;
    background-color: #1a9ed9;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    transition: 0.12s ease-out;
    min-height: 62px;
  
    &:hover{
      transform: scale(1.03);
    } 

    &.disabled{
      transform: scale(1);
      opacity: 0.3;
    }
  }
}