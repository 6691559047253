.lead-list {
  &-container {
    display: flex;
    flex-direction: column;
  }
  &-item {
    width: 100%;
    max-width: 100%;
    border-radius: 10px;
    background-color: var(--white-color);
    padding: 18px 150px 18px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid var(--lead-list-border-color);
    transition: var(--transition-simple);
    cursor: pointer;

    @media screen and (max-width: 1024px) {
      padding: 18px 20px 18px 20px;
    }

    &:hover {
      transform: scale(1.03);
    }
    &.walkthrough{
      position: relative;
      transform: scale(1);
      cursor: initial;
    }
    &:not(:last-child) {
      margin-bottom: 15px;
    }
    &-left {
      display: flex;
      align-items: center;
      &-avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 17px;
        min-width: 43px;
        max-width: 43px;
        min-height: 43px;
        max-height: 43px;
        border-radius: 50%;
        background-color: var(--lead-list-avatar-color);
        padding-right: 1px;
      }
      &-info {
        display: flex;
        flex-direction: column;
        &-name {
          display: flex;
          align-items: center;
          white-space: nowrap;
          font-size: 17px;
          font-weight: 600;
          color: var(--text-color-primary);
          margin-bottom: 3px;
          &-new {
            margin-left: 5px;
          }
        }
        &-date {
          font-size: 15px;
          font-weight: 500;
          color: var(--text-color-secondary);
        }
      }
    }
    &-center {
      font-size: 16px;
      font-weight: 600;
      color: var(--text-color-primary);

      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
    &-right {
      font-size: 16px;
      font-weight: 600;
      color: var(--text-color-primary);

      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
  }
}
