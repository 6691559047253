.waiting-screen-component{
  &-container{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    left: 0;
    top: 0;
  }
  &-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 603px;
  }
  &-label{
    font-size: 16px;
    font-weight: 800;
    text-transform: uppercase;
    color: var(--blue-color);
    margin-bottom: 5px;
    text-align: center;
    
    @media screen and (max-width: 730px){
      font-size: 14px;
    }
  }

  &-title{
    font-size: 45px;
    font-weight: 800;
    text-align: center;
    color: var(--text-color-primary);
    margin-bottom: 55px;

    @media screen and (max-width: 730px){
      font-size: 30px;
    }
  }
}