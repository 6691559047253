.color-input{
  &-container{
    width: 100%;
    display: flex;
  }
  &-holder{
    display: flex;
    align-items: center;
    width: 100%;
    height: 62px;
    padding: 12px;
    border-radius: var(--input-border-radius);
    border: solid 1px var(--input-border-color-inactive);
    transition: 0.25s ease;

    &.error {
      border-color: var(--input-border-color-error) !important;
    }

    &:hover {
      border-color: var(--input-border-color-hover);

      & .color-input-right-label{
        color: var(--input-border-color-hover);
      }
    }

    &.focused {
      border-color: var(--input-border-color-focus);
      box-shadow: var(--input-shadow-color-focus);
    }

    & .ant-color-picker-color-block{
      border-radius: 50% !important;
      width: 38px;
      height: 38px;
    }
  }

  &-right{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-left: 12px;

    &-label{
      font-size: 14px;
      font-weight: 500;
      transition: 0.15s ease-in;
      margin-bottom: 2px;
      color: var(--input-placeholder-color);
      cursor: text;

      &.focused{
        color: var(--input-border-color-focus) !important;
      }

      &.error{
        color: var(--input-border-color-error) !important;
      }
    }

    &-input{
      font-size: 17px;
      font-weight: 500;
      color: var(--input-text-color);
    }
  }
}