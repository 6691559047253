.container{
  width: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1024px) {
    height: 100%;
  }
}
.form{
  width: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1024px) {
    height: 100%;
    justify-content: space-between;
  }
}
.input{
  width: 100%;
  margin-bottom: 35px;
  height: 132px;
  & > textarea {
    height: 100%;
    resize: none;
  }
  @media screen and (max-width: 1024px) {
    height: 100%;
  }
}
.submit{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px !important;
  min-height: 62px;
  & > svg{
    margin-right: 9px;
  }
}