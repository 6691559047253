.container{
  height: 52px;
  border-radius: 10px;
  background-color: #f6f7f9;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  color: #151f26;
  padding: 0 24px;
  white-space: nowrap;
  cursor: pointer;
  & > svg {
    margin-right: 10px;
    margin-top: 1px;
    height: 20px;
    width: auto;
  }
  transition: var(--transition-simple-fast);

  &:hover {
    transform: scale(1.06);
  }
  &:active{
    transform: scale(0.95);
    filter: brightness(0.7);
  }
}