.signup-page{
  &-logo{
    display: none;
    @media screen and (max-width: 1024px) {
      display: flex;
      padding-bottom: 20px;
      order: 0;
      margin-bottom: 0;
    }
  }
  &-container{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 35px;

    @media screen and (max-width: 1024px) {
      justify-content: flex-start;
      flex-direction: column;
      padding: 30px 0px 150px;
      height: auto;
      background-color: var(--background-color);
      overflow: hidden;
    }
  }
  &-left{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 675px;
    margin-right: 180px;
    padding: 55px;
    border-radius: 25px;
    background-image: linear-gradient(to bottom, #f5f7f9 30%, #0075ff 150%);
    position: relative;

    @media screen and (max-width: 1280px) {
      margin-right: 100px;
    }

    @media screen and (max-width: 1024px) {
      min-width: 100%;
      max-width: 100%;
      align-items: center;
      margin-right: 0;
      border-radius: 0;
      padding: 48px 10px 10px;
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
      order: 2;
    }

    &-logo{
      margin-bottom: 32px;
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }

    &-title{
      font-size: 32px;
      font-weight: 800;
      margin-bottom: 8px;
      color: #0d3051;
      max-width: 430px;
      text-align: center;

      & > strong{
        color: #0075ff;
      }

      @media screen and (max-width: 1024px){
        font-size: 24px;
        max-width: 340px;
      }
    }

    &-description{
      font-size: 16px;
      font-weight: 500;
      color: #6e8397;
      margin-bottom: 38px;
      @media screen and (max-width: 1024px){
        font-size: 15px;
        max-width: 340px;
        text-align: center;
        margin-bottom: 19px;
      }
    }

    &-features{
      width: 100%;
      display: flex;
      flex-direction: column;
      width: 100%;
      z-index: 2;
    }

    &-feature{
      width: 100%;
      border-radius: 32px;
      background-color: #fff;
      display: flex;
      display: flex;
      align-items: center;
      padding: 25px 65px 25px 25px;
      box-shadow: 0 7px 15px 0 rgba(0, 117, 255, 0.2);
      @media screen and (max-width: 1024px){
        flex-direction: column;
        align-items: flex-start;
      }

      &.ai{
        box-shadow: 0 13px 18px 0 rgba(59, 25, 187, 0.3);
      }

      &-icon{
        margin-right: 18px;
        min-width: 45px;
        max-width: 45px;
        @media screen and (max-width: 1024px){
          margin-bottom: 20px;
          margin-right: 0;
        }
      }
      &-right{
        display: flex;
        flex-direction: column;
      }
      &-title{
        font-size: 20px;
        font-weight: bold;
        color: #0d3051;
        margin-bottom: 4px;
      }
      &-desc{
        font-size: 15px;
        font-weight: 500;
        color: #0d3051;
        opacity: 0.7;
        margin-bottom: 4px;
      }

      &:not(:last-child){
        margin-bottom: 10px;
      }
    }
    &-decoration{
      font-size: 382px;
      -webkit-filter: blur(4px);
      filter: blur(4px);
      position: absolute;
      right: -150px;
      bottom: -100px;
      z-index: 1;
    }
  }
  &-right{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 650px;
    align-items: flex-start;

    @media screen and (max-width: 1024px) {
      min-width: 100%;
      max-width: 100%;
      align-items: center;
      order: 1;
      background-color: #fff;
      padding: 0 10px;
    }
  }
  &-holder{
    width: 100%;
    max-width: 410px;
    min-width: 410px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 1024px) {
      box-shadow: none;
      padding: 35px 0px 42px;
      max-width: 350px;
      min-width: 100%;
    }
    &-title{
      font-size: 26px;
      font-weight: 800;
      color: var(--text-color-primary);
      text-align: center;
      margin-bottom: 8px;
      @media screen and (max-width: 1024px) {
        font-size: 25px;
        max-width: 325px;
      }
    }
    &-description{
      font-size: 16px;
      font-weight: 500;
      color: var(--text-color-secondary);
      margin-bottom: 35px;
      text-align: center;
      @media screen and (max-width: 1024px) {
        font-size: 15px;
        max-width: 325px;
        margin-bottom: 22px;
      }
    }
    &-form{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 45px;
      @media screen and (max-width: 1024px){
        margin-bottom: 22px;
        max-width: 325px;
      }
      &-input{
        margin-bottom: 15px;

        &.password{
          margin-bottom: 4px;
        }

        &-password-label{
          font-size: 11px;
          font-weight: 600;
          color: var(--text-color-secondary);
          text-align: left;
          margin-bottom: 15px;
          padding-left: 5px;
          width: 100%;

          @media screen and (max-width: 1024px){
            font-size: 8px;
            font-weight: 600;
          }
        }
      }
      &-captcha{
        margin-bottom: 35px;
        @media screen and (max-width: 1024px){
          margin-bottom: 25px;
        }
      }
      &-submit{
        width: 100%;
        box-shadow: 0 7px 15px 0 rgba(0, 117, 255, 0.2);
        @media screen and (max-width: 1024px){
          font-size: 18px !important;
        }
        &-loader{
          width: 100% !important;
          & > div {
            background-color: #fff;
          }
        }
      }
    }
    &-oauth{
      width: 100%;
      margin-bottom: 16px;
      display: flex;
      justify-content: center;
      &-button{
        width: 100%;
        &:not(:last-child){
          margin-right: 10px;
        }
      }
      &-separator{
        margin-bottom: 16px;
        font-size: 14px;
        font-weight: 500;
        color: var(--text-color-secondary);
      }
      @media screen and (max-width: 1024px){
        max-width: 325px;
      }
    }
    &-login{
      &-holder{
        width: 100%;
        text-align: center;
        font-size: 15px;
        font-weight: 600;
        color: var(--text-color-secondary);
        @media screen and (max-width: 1024px){
          font-size: 14px;
        }
      }
      &-link{
        color: var(--blue-color);
        cursor: pointer;
      }
    }
  }
}