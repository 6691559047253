.feedback{
  &-holder{
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    z-index: 2;
    align-items: center;
    justify-content: center;
  }
  &-background{
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
  }
}