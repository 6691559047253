.feedback-fe{
  &-holder{
    position: relative;
    border-radius: 10px;
    background-image: linear-gradient(to top, #fff, #fff2f2 50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 510px;
    width: 100%;
    height: fit-content;

    @media screen and (max-width: 515px){
      height: 100%;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}