.container{
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);
  background-color: rgb(237 242 246 / 67%);
  overflow-y: scroll;
}
.background{
  z-index: 9;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9;
  left: 0;
  top: 0;
}
.content{
  width: 100%;
  max-width: 745px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 11;
}
.icon{
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  min-height: 61px;
  max-height: 61px;
  min-width: 61px;
  max-width: 61px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  background-color: #fff;
  margin-bottom: 25px;
  border-radius: 30.5px;
}
.title{
  font-size: 26px;
  font-weight: bold;
  color: #171e29;
  text-align: center;
  margin-bottom: 6px;
}
.description{
  font-size: 16px;
  font-weight: 500;
  color: #6b7280;
  margin-bottom: 32px;
  text-align: center;
}
.requestChanges{
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgba(0, 145, 255, 0.4);
  background-color: #0091ff;
  color: #fff;
  padding: 0 15px;
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 42px;
  cursor: pointer;
  transition: var(--transition-simple);
  &:hover{
    transform: scale(1.1);
  }
  &:active{
    transform: scale(0.85);
    filter: brightness(0.7)
  }
}
.label{
  min-height: 40px;
  border-radius: 20px;
  padding: 10px 15px;
  color: #6b7280;
  font-size: 14px;
  font-weight: 500;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  @media screen and (max-width: 1024px){
    font-size: 12px;
  }
}