.mobile-floater-component{
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: 18px;
  justify-content: center;
  &-copy-link{
    padding: 0 17px !important;
    border-radius: 20px !important;
    box-shadow: 0 5px 15px 0 rgba(9, 162, 22, 0.3) !important;
  }
}