.tapform-code-snippet-component{
  &-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    border-width: 2px;
    border-image-source: linear-gradient(to bottom, #9fa8b2 0%, #636f7d);
    border-image-slice: 1;
    background-image: linear-gradient(to bottom, #0d1117, #0d1117), linear-gradient(to bottom, #9fa8b2 0%, #636f7d);
    background-origin: border-box;
    background-clip: content-box, border-box;
    padding: 2px;
  }
  &-title{
    padding: 35px;
    font-size: 19px;
    font-weight: bold;
    color: #fff;
    display: flex;
    align-items: center;
    &-icon{
      margin-left: 8px;
    }
  }
  &-code{
    color: #a5d6ff;
    font-size: 15px;
    font-weight: 500;
    word-wrap: break-word;
    &-holder{
      padding: 0 35px 35px;
    }
  }
  &-copy{
    width: fit-content;
    width: -moz-fit-content;
    height: 49px;
    border-radius: 24.5px;
    background-color: #343f4f;
    padding: 0 20px;
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: bold;
    margin: 0 auto;
    margin-bottom: 25px;
    color: #fff;
    cursor: pointer;
    transition: var(--transition-simple);
    
    &:hover{
      transform: scale(1.07);
      filter: brightness(1.2);
    }

    &:active{
      transform: scale(0.95);
      filter: brightness(0.7);
    }

    &-icon{
      margin-right: 10px;
    }
  }
}