.form-template-popup-component{
  &-container{
    display: flex;
    max-width: 904px;
    width: 100%;
    border-radius: 20px;
    background-color: #fff;
    overflow: hidden;
    position: relative;
    @media screen and (max-width: 1024px){
      flex-direction: column;
      max-width: 488px;
    }

    @media screen and (max-width: 488px){
      border-radius: 0;
    }
  }
  &-left{
    display: flex;
    flex-direction: column;
    min-width: 488px;
    max-width: 488px;
    background-color: #dbe4ea;
    justify-content: space-between;
    @media screen and (max-width: 1024px){
      min-width: 100%;
      max-width: 100%;
    }
    &-top{
      width: 100%;
      height: 100%;
      display: flex;
      overflow: hidden;
      position: relative;
      flex-direction: column;
    }
    &-bottom{
      display: flex;
      justify-content: center;
      padding: 35px 45px;

      @media screen and (max-width: 1024px){
        display: none;
      }
  
      &-disclaimer{
        width: 100%;
        background-color: #fff;
        padding: 15px 20px;
        border-radius: 10px;
        display: flex;
        align-items: center;
  
        &-icon{
          font-size: 15px;
          line-height: 15px;
          margin-right: 15px;
        }
        &-text{
          font-size: 15px;
          font-weight: 600;
          color: var(--text-color-primary);

          & > span {
            color: var(--blue-color);
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }
  &-images{
    &-arrow-left{
      position: absolute;
      top: 192px;
      left: 15px;
    }
    &-arrow-right{
      position: absolute;
      top: 192px;
      right: 15px;
    }
    &-slider{
      width: 100%;
      display: flex;
      height: 100%;
      overflow: hidden;
      padding: 75px 35px 0px 25px;

      &-items{
        display: flex;
        user-select: none;
        @media screen and (max-width: 1024px){
          margin-bottom: 25px;
        }
      }

      &-item{
        width: 407px;
        padding: 20px;
        border-radius: 20px;
        background-color: #cdd6de;
        display: flex;
        height: auto;
        height: fit-content;
        height: -moz-fit-content;
        user-select: none;

        @media screen and (max-width: 1024px){
          width: calc(100vw - 45px);
          max-width: 407px;
          padding: 10px;
        }

        &-image{
          width: 100%;
          min-height: 239px;
          height: auto;
          border-radius: 10px;
          user-select: none;
          opacity: 0;
          transition: var(--transition-simple);

          @media screen and (max-width: 1024px){
            min-height: auto;
          }

          &.loaded{
            opacity: 1;
          }
        }

        &:not(:last-child){
          margin-right: 25px;
        }
      }
    }
    &-nav{
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 1024px){
        margin-bottom: 25px;
      }

      &-item{
        min-width: 9px;
        max-width: 9px;
        min-height: 9px;
        max-height: 9px;
        display: flex;
        border-radius: 50%;
        background-color: #0a133c;
        opacity: 0.5;
        transition: var(--transition-simple-fast);
        cursor: pointer;
        
        &:not(:last-child){
          margin-right: 8px;
        }

        &.active{
          opacity: 1;
          min-width: 12px;
          max-width: 12px;
          min-height: 12px;
          max-height: 12px;
        }
      }
    }
  }
  &-right{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 55px 35px 35px;

    @media screen and (max-width: 1024px){
      padding: 35px 25px;
    }

    &-title{
      font-size: 27px;
      font-weight: bold;
      color: var(--text-color-primary);
      margin-bottom: 10px;
    }

    &-description{
      font-size: 15px;
      font-weight: 500;
      color: var(--text-color-secondary);
      margin-bottom: 35px;
      @media screen and (max-width: 1024px){
        margin-bottom: 25px;
      }
    }

    &-includes{
      &-title{
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
        color: var(--text-color-primary);
      }
      &-items{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 68px;
        @media screen and (max-width: 1024px){
          margin-bottom: 35px;
        }
      }
      &-item{
        width: 100%;
        display: flex;
        align-items: center;
        font-size: 17px;
        font-weight: 600;
        color: var(--text-color-secondary);

        &:not(:last-child){
          margin-bottom: 15px;
        }

        &-icon{
          width: 40px;
          margin-right: 12px;
        }
      }
    }
    &-submit{
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--blue-color);
      color: #fff;
      font-size: 17px;
      font-weight: bold;
      width: 100%;
      height: 52px;
      border-radius: 26px;
      transition: var(--transition-simple);
      cursor: pointer;

      &:hover{
        transform: scale(1.05);
        filter: brightness(1.15);
      }

      &:active{
        transform: scale(0.95);
        filter: brightness(0.7);
      }

      &-icon{
        margin-left: 7.5px;
        margin-top: 2px;

        & path:nth-child(2){
          fill: #fff;
        }
      }
    }
  }
  &-close{
    position: absolute;
    top: 15px;
    right: 15px;
  }
}