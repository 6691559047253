.onboarding-three-page{
  &-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center safe;
    align-items: center;
    background-color: var(--background-color);
    padding: 35px;

    @media screen and (max-width: 1024px) {
      justify-content: flex-start;
      padding: 35px 0px 0;
      height: auto;
    }
  }
  &-content{
    width: 100%;
    max-width: 1130px;
    box-shadow: 0 15px 40px 0 rgba(0, 0, 0, 0.06);
    background-color: var(--foreground-background);
    border: var(--foreground-border);
    border-radius: 20px;
    padding: 65px 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 1024px) {
      box-shadow: none;
      padding: 0;
      max-width: 100%;
      border: none;
    }
    & > .template-ai-card-component-container{
      margin-bottom: 65px;
      @media screen and (max-width: 1024px) {
        margin-bottom: 35px;
      }
    }
    &-label{
      font-size: 26px;
      font-weight: bold;
      color: var(--blue-color);
      text-align: center;
      margin-bottom: 5px;
      font-family: Caveat;
      @media screen and (max-width: 1024px) {
        font-size: 21px;
      }
    }
    &-title{
      max-width: 643px;
      font-size: 35px;
      font-weight: 800;
      color: var(--text-color-primary);
      margin-bottom: 65px;
      text-align: center;
      @media screen and (max-width: 1024px) {
        font-size: 26px;
        padding: 0 35px;
        margin-bottom: 25px;
      }
    }
    &-templates{
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 45px;
      padding: 0 35px;
      @media screen and (max-width: 1024px) {
        max-width: calc(575px + 70px);
        padding: 0 25px;
      }
      &-template{
        margin-bottom: 45px;
        width: calc(50% - 22.5px) !important;

        @media screen and (max-width: 1024px) {
          width: 100% !important;
          margin-right: 0 !important;
          margin-bottom: 20px;
        }

        &:nth-child(odd){
          margin-right: 45px;
        }
      }
    }
    &-schedule{
      width: 100%;
      border-radius: 20px;
      background-image: linear-gradient(197deg, #00c4ff 0, #0091ff 100%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 58px 58px 65px;
      overflow: hidden;
      position: relative;

      &-label{
        position: relative;
        margin-bottom: 7px;
        z-index: 2;
        &-text{
          position: absolute;
          top: 10px;
          left: 10px;
          font-size: 14px;
          font-weight: 800;
          color: var(--text-color-primary);
          text-transform: uppercase;
        }
      }
      &-title{
        max-width: 700px;
        text-align: center;
        font-size: 32px;
        font-weight: 800;
        color: #fff;
        margin-bottom: 35px;
        z-index: 2;
      }
      &-button{
        height: 52px;
        border-radius: 26px;
        background-color: #fff;
        font-size: 17px;
        font-weight: bold;
        color: var(--blue-color);
        padding: 0 25px;
        line-height: 52px;
        cursor: pointer;
        transition: var(--transition-simple);
        z-index: 2;

        &-icon{
          margin-left: 6px;
          line-height: 52px;
        }

        &:hover{
          transform: scale(1.1);
        }
      }
      &-background{
        position: absolute;
        width: 120%;
        height: 140%;
        left: 0;
        top: -70px;
      }
    }
  }
}