html {
  --transition-simple: 0.50s cubic-bezier(0.075, 0.82, 0.165, 1);
  --transition-simple-fast: 0.30s cubic-bezier(0.075, 0.82, 0.165, 1);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInSlideIn {
  0% {
    opacity: 0;
    transform: translateY(5%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
