.onboarding-one-page{
  &-container{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--background-color);
    padding: 35px;

    @media screen and (max-width: 1024px) {
      justify-content: flex-start;
      padding: 35px 00px 0px;
      height: auto;
      min-height: 100%;
      max-height: 100%;
    }
  }
  &-content{
    width: 100%;
    max-width: 584px;
    box-shadow: 0 15px 40px 0 rgba(0, 0, 0, 0.06);
    background-color: var(--foreground-background);
    border: var(--foreground-border);
    border-radius: 20px;
    padding: 65px 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 1024px) {
      box-shadow: none;
      padding: 30px 20px 45px;
      max-width: 100%;
      width: 100%;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border: none;
      border-radius: 25px;
    }
    &-title{
      font-size: 35px;
      font-weight: 800;
      text-align: center;
      margin-bottom: 10px;
      color: var(--text-color-primary);
      @media screen and (max-width: 1024px) {
        font-size: 24px;
        margin-bottom: 8px;
        max-width: 550px;
      }
    }
    &-description{
      font-size: 17px;
      font-weight: 500;
      color: var(--text-color-secondary);
      text-align: center;
      margin-bottom: 35px;
      @media screen and (max-width: 1024px) {
        font-size: 15px;
        margin-bottom: 32px;
        max-width: 550px;
      }
    }
    &-form{
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;

      &-input{
        width: 100%;
        margin-bottom: 15px;
        &:not(:first-child){
          margin-bottom: 45px;
        }
      }

      &-submit{
        width: 100%;
      }
    }
  }
}