.feedback-stats{
  &-graphic{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
  &-label{
    font-size: 19px;
    font-weight: 800;
    margin-bottom: 8px;
    color: #d96824;
    padding: 234px 35px 0;
    text-align: center;
  }
  &-title{
    font-size: 32px;
    font-weight: 800;
    color: #252a31;
    margin-bottom: 35px;
    padding: 0 35px;
    text-align: center;
  }
  &-card{
    &-holder{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 35px;
    margin-bottom: 38px;
    }

    width: calc(50% - 10px);
    display: flex;
    border-radius: 15px;
    box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.08);
    background-color: #fff;
    padding: 20px;
    flex-direction: column;

    &-icon{
      max-width: 62px;
      min-width: 62px;
      max-height: 62px;
      min-height: 62px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f4f4f4;
      border-radius: 50%;
      margin-bottom: 23px;
    }

    &-value{
      font-size: 40px;
      font-weight: 800;
      color: #252a31;
      margin-bottom: 3px;
    }

    &-label{
      font-size: 20px;
      font-weight: 600;
      color: #7c7f83;
    }
  }

  &-continue{
    width: calc(100% - 70px);
    height: 62px;
    min-height: 62px;
    border-radius: 10px;
    border: solid 1px #e3e3e3;
    background-color: #1a9ed9;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    margin: 0 35px 35px;
    cursor: pointer;
    transition: 0.12s ease-out;
  
    &:hover{
      transform: scale(1.03);
    }
  }
}