.container{
  display: flex;
  flex-direction: column;
  background-color: var(--white-color);
  border: 1px solid var(--lead-list-border-color);
  box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 20px;
}
.headline{
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  font-size: 17px;
  font-weight: 600;
  color: var(--text-color-primary);
}
.utmItem{
  display: flex;
  width: 100%;
  text-decoration: none;
  &:not(:last-child){
    margin-bottom: 15px;
  }
}
.utmItemParam{
  display: flex;
  font-size: 14px;
  font-weight: 700;
  margin-right: 9px;
  color: var(--text-color-secondary);
}
.utmItemText{
  display: flex;
  font-size: 14px;
  font-weight: 600;
  color: var(--text-color-primary);
  align-items: center;
  word-break:break-all;
}