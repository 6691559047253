.lead-info-component{
  &-container{
    display: flex;
    flex-direction: column;
    background-color: var(--white-color);
    border: 1px solid var(--lead-list-border-color);
    box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 20px;
  }
  &-headline{
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    &-left{
      display: flex;
      margin-right: 12px;
      &-avatar{
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 43px;
        max-width: 43px;
        min-height: 43px;
        max-height: 43px;
        border-radius: 50%;
        padding-right: 2px;
        font-size: 27px;
        background-color: var(--lead-list-avatar-color);
      }
    }
    &-right{
      display: flex;
      flex-direction: column;
      &-name{
        margin-bottom: 3px;
        font-size: 17px;
        font-weight: 600;
        color: var(--text-color-primary);
      }
      &-date{
        margin-bottom: 3px;
        font-size: 15px;
        font-weight: 500;
        color: var(--text-color-secondary);
      }
    }
  }
  &-fact{
    cursor: pointer;
    display: flex;
    text-decoration: none;
    &:not(:last-child){
      margin-bottom: 15px;
    }
    &-icon{
      font-size: 16px;
      margin-right: 9px;
    }
    &-text{
      font-size: 15px;
      font-weight: 600;
      color: var(--text-color-primary);
      display: flex;
      align-items: center;
    }
  }
}