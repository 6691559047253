.form-changer-component{
  display: flex;
  position: relative;
  width: 166px;

  @media screen and (min-width: 1025px){
    margin-right: 10px;
  }

  &-holder{
    display: flex;
    align-items: center;
    padding: 7px 15px 7px 7px;
    border-radius: 8px;
    background-color: transparent;
    transition: var(--transition-simple);
    cursor: pointer;
    width: 100%;

    &:hover{
      background-color: #e3e9ee;
    }

    &.active{
      background-color: #d4dde4;
    }
  }
  &-form{
    &-icon{
      background-color: #fff;
      border-radius: 7px;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
      width: 26px;
      min-width: 26px;
      height: 26px;
      object-fit: contain;
      margin-right: 10px;
      overflow: hidden;
    }
    &-name{
      font-size: 15px;
      font-weight: bold;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      transition: var(--transition-simple);
      width: 100%;
    }
  }
  &-dropdown{
    &-icon{
      margin-left: 11px;
      margin-top: 1px;
      width: 12px;
      min-width: 12px;
      transition: var(--transition-simple);

      &.active{
        transform: rotate(180deg);
      }
    }
    &-holder{
      position: absolute;
      top: 48px;
      left: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 5px;
      border-radius: 10px;
      box-shadow: 0 20px 35px 0 rgba(0, 0, 0, 0.2);
      overflow: hidden;
      background-color: #fff;
    }
    &-item{
      &:not(:last-child){
        margin-bottom: 1px;
      }
      width: 100%;
      display: flex;
      align-items: center;
      padding: 7px 15px 7px 7px;
      border-radius: 8px;
      transition: var(--transition-simple);
      cursor: pointer;
      
      & > span {
        opacity: 0.43;
      }

      &.active,
      &:hover{
        background-color: #f3f6f8;

        & > span{
          opacity: 1;
        }
      }
      &-create{
        width: 100%;
        height: 40px;
        border-radius: 8px;
        background-color: #0091ff;
        cursor: pointer;
        transition: var(--transition-simple);
        color: #fff;
        font-size: 15px;
        font-weight: bold;
        margin-top: 10px;
      }
      &-settings{
        width: 100%;
        height: 40px;
        border-radius: 8px;
        background-color: #d4dde4;
        cursor: pointer;
        transition: var(--transition-simple);
        color: var(--text-color-primary);
        font-size: 15px;
        font-weight: bold;
        margin-top: 5px;
      }
      &-logout{
        width: 100%;
        height: 30px;
        border-radius: 8px;
        background-color: #f0f0f1;
        cursor: pointer;
        transition: var(--transition-simple);
        color: var(--text-color-primary);
        font-size: 13px;
        font-weight: bold;
        margin-top: 12px;
      }
    }
  }
}