.welcome-page{
  &-container{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--background-color);
    padding: 35px;

    @media screen and (max-width: 1024px) {
      justify-content: flex-start;
      padding: 25px 20px 150px;
      height: auto;
    }
  }
  &-content{
    width: 100%;
    max-width: 1200px;
    box-shadow: 0 15px 40px 0 rgba(0, 0, 0, 0.06);
    background-color: var(--foreground-background);
    border: var(--foreground-border);
    border-radius: 20px;
    padding: 35px;
    display: flex;
    justify-content: space-between;
  }
  &-left{
    margin-right: 65px;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 398px;

    &-title{
      font-size: 35px;
      font-weight: 800;
      color: var(--text-color-primary);
      margin-top: 30px;
      margin-bottom: 10px;
    }
    &-description{
      font-size: 17px;
      font-weight: 500;
      color: var(--text-color-secondary);
      margin-bottom: 70px;
    }
    &-faq{
      display: flex;
      flex-direction: column;
      width: 100%;
      border-radius: 10px;
      background-color: #f1f4f5;
      padding: 5px 5px 5px;
      margin-bottom: 70px;

      &-item{
        display: flex;
        align-items: center;
        padding: 0px 15px 0;

        &:not(:last-child) > .welcome-page-left-faq-item-text{
          border-bottom: solid 1px #D6DDE2;
        }

        &-icon{
          font-size: 15px;
          font-weight: normal;
          margin-right: 15px;
        }
        &-text{
          padding: 15px 0;
          font-size: 15px;
          font-weight: 600;
          color: var(--text-color-primary);
          
          & > a {
            color: var(--blue-color);
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }
    &-actions{
      display: flex;
      align-items: center;

      &-submit{
        padding: 0 35px !important;
        margin-right: 40px;
      }

      &-skip{
        font-size: 19px;
        font-weight: bold;
        color: var(--text-color-secondary);
        background-color: transparent;
        cursor: pointer;
      }
    }
  }
  &-right{
    min-width: 606px;
    max-width: 606px;
    display: flex;
    flex-direction: column;

    &-source{
      margin-bottom: 20px;
    }

    &-action{
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 35px;
      border-radius: 15px;
      background-color: #0365d3;
      position: relative;
      overflow: hidden;

      &-image{
        width: 335px;
        position: absolute;
        right: 0px;
        top: -8px;
      }

      &-left{
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 269px;

        &-title{
          font-size: 20px;
          font-weight: bold;
          color: #fff;
          margin-bottom: 5px;
        }
        &-description{
          font-size: 16px;
          font-weight: 500;
          color: #fff;
          margin-bottom: 18px;
        }
        &-button{
          height: 41px;
          border-radius: 20.5px;
          background-color: #428BDE;
          display: flex;
          align-items: center;
          padding: 0 15px;
          font-size: 16px;
          font-weight: bold;
          color: #fff;
          width: fit-content;
          width: -moz-fit-content;
          transition: var(--transition-simple);
          cursor: pointer;

          &:hover{
            transform: scale(1.07);
            filter: brightness(1.2);
          }

          &:active{
            transform: scale(0.95);
            filter: brightness(0.7);
          }

          &-icon{
            margin-left: 10px;
          }
        }
      }
    }
  }
}