.leads-page {
  &-container {
    width: 100%;
    display: flex;
    justify-content: center;
    opacity: 0;
    animation-name: fadeIn;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
    padding-bottom: 140px;
    @media screen and (max-width: 1024px) {
      padding: 0 20px;
      padding-bottom: 100px;
    }

    &.walkthrough{
      position: relative;
      z-index: 10;
    }
  }
  &-holder {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
  }
  &-title {
    font-size: 26px;
    font-weight: bold;
    color: var(--text-color-primary);
    margin-bottom: 5px;
  }
  &-table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    @media screen and (max-width: 1024px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &-subtitle {
    font-size: 16px;
    font-weight: 500;
    color: var(--text-color-secondary);

    @media screen and (max-width: 1024px) {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-bottom: 15px;
    }
  }
  &-info {
    font-size: 16px;
    font-weight: 600;
    color: var(--text-color-secondary);

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
  &-leads {
    margin-bottom: 25px;
  }
  &-load-more{
    width: fit-content;
    margin: 0 auto;
  }
}
