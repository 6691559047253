.feedback-submitted{
  &-holder{
    position: relative;
    border-radius: 10px;
    background-image: linear-gradient(to top, #fff, #fff2f2 50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 510px;
    width: 100%;
    height: fit-content;

    @media screen and (max-width: 515px){
      height: 100%;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    overflow-y: auto;
  }
  &-graphic{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
  &-label{
    font-size: 19px;
    font-weight: 800;
    margin-bottom: 8px;
    color: #d96824;
    padding: 350px 35px 0;
    text-align: center;
  }
  &-title{
    font-size: 32px;
    font-weight: 800;
    color: #252a31;
    margin-bottom: 139px;
    padding: 0 35px;
    text-align: center;
  }
  &-close{
    width: calc(100% - 70px);
    height: 62px;
    min-height: 62px;
    border-radius: 10px;
    font-size: 18px;
    font-weight: bold;
    margin: 0 35px 35px;
    cursor: pointer;
    transition: 0.12s ease-out;
    box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.05);
    background-color: #D1EAF6;
    color: #1a9ed9;
  
    &:hover{
      transform: scale(1.03);
    }
  }
}