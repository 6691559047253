.container{
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 80px 20px;

  @media screen and (max-width: 745px){
    padding: 20px 20px 120px;
  }
}
.holder{
  display: flex;
  flex-direction: column;
  max-width: 509px;
}
.icon{
  min-width: 61px;
  min-height: 61px;
  max-width: 61px;
  max-height: 61px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
  margin-bottom: 12px;
}
.title{
  font-size: 26px;
  font-weight: bold;
  color: var(--text-color-primary);
  margin-bottom: 6px;
}
.description{
  font-size: 16px;
  font-weight: 500;
  color: #6b7280;
  margin-bottom: 22px; 
}
.form{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.input {
  width: 100%;
  margin-bottom: 22px;
  height: 182px;
  & > textarea {
    border-width: 2px;
    height: 100%;
    resize: none;
  }
}
.submit{
  padding: 0 23px;
  height: 50px;
  background-color: var(--blue-color);
  font-size: 16px;
  font-weight: 600;
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgba(0, 145, 255, 0.4);
  color: #fff;
  min-width: 172px;
  cursor: pointer;
  transition: var(--transition-simple);
  &:hover{
    transform: scale(1.05);
    filter: brightness(1.3);
  }
  &:active{
    transform: scale(0.95);
    filter: brightness(0.7);
  }
  &.loading{
    cursor: initial;
    opacity: 0.6;
    &:hover{
      transform: scale(1);
      filter: brightness(1);
    }
    &:active{
      transform: scale(1);
      filter: brightness(1);
    }
  }
}
.spinner{
  border-top-color: #fff;
}