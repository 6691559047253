.estimate-summary{
  &-container{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    border-radius: 10px;
    background-color: var(--white-color);
    border: 1px solid var(--lead-list-border-color);
    box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.05);
  }
  &-image{
    width: 100%;
    height: auto;
    object-fit: contain;
  }
  &-title{
    margin-top: 25px;
    margin-bottom: 25px;
    font-size: 25px;
    font-weight: bold;
    color: var(--text-color-primary);
    padding: 0 20px;
  }
  &-price{
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    padding: 0 20px;
    &-label{
      font-size: 13px;
      font-weight: bold;
      color: var(--text-color-primary);
      margin-bottom: 3px;
    }
  }
  &-description{
    padding: 0 20px 20px;
    font-size: 14px;
    border-bottom: 1px solid var(--lead-list-border-color);
  }
  &-warning{
    padding: 20px;
    display: flex;
    &-left{
      display: flex;
      margin-right: 10px;
      &-avatar{
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 40px;
        max-width: 40px;
        min-height: 40px;
        max-height: 40px;
        border-radius: 50%;
        padding-right: 2px;
        font-size: 15px;
        background-color: var(--lead-list-avatar-color);
        padding-left: 4px;
        padding-bottom: 4px;
      }
    }
    &-right{
      display: flex;
      flex-direction: column;
      &-label{
        font-size: 10px;
        font-weight: 500;
        color: var(--text-color-secondary);
        margin-bottom: 2px;
      }
      &-title{
        font-size: 13px;
        font-weight: 500;
        color: var(--text-color-primary);
      }
    }
  }
}