.header-progress-bar{
  &-container{
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    display: flex;
  }
  &-progress{
    transition: var(--transition-simple);
    background-color: var(--blue-color);
    height: 100%;
    width: 100%;
    &.ai{
      background-color: var(--ai-color);
    }
  }
}