.walkthrough-tooltip-component{
  &-container{
    position: absolute;
    bottom: -586px;
    left: 55px;
    width: 566px;
    height: 566px;
  }
  &-holder{
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 4px;
    background-color: #fff;
    align-items: center;
  }
  &-pointer{
    width: 0; 
    height: 0; 
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid white;
    border-radius: 7.5px;
    position: absolute;
    top: -12px;

    &.left{
      left: 15px;
    }

    &.right{
      right: 15px;
    }
  }
  &-icon-left{
    position: absolute;
    top: 30px;
    left: 30px;
    font-size: 55px;
  }
  &-icon-right{
    position: absolute;
    top: 30px;
    right: 30px;
    font-size: 55px;
  }
  &-title{
    margin-top: 30px;
    max-width: 354px;
    font-size: 22px;
    font-weight: 800;
    text-align: center;
    color: var(--text-color-primary);
    margin-bottom: 8px;
  }
  &-description{
    max-width: 354px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: var(--text-color-secondary);
    margin-bottom: 25px;
  }
  &-submit{
    padding: 0 30px !important;
    margin-bottom: 25px;
  }
}