.login-page{
  &-container{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--background-color);
    padding: 35px;

    @media screen and (max-width: 1024px) {
      justify-content: flex-start;
      padding: 25px 10px 150px;
      height: auto;
    }
  }
  &-holder{
    width: 100%;
    max-width: 540px;
    box-shadow: 0 15px 40px 0 rgba(0, 0, 0, 0.06);
    background-color: var(--foreground-background);
    border: var(--foreground-border);
    border-radius: 20px;
    padding: 55px 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 1024px) {
      box-shadow: none;
      padding: 35px 25px;
      max-width: 350px;
    }
    &-title{
      font-size: 35px;
      font-weight: 800;
      color: var(--text-color-primary);
      text-align: center;
      margin-bottom: 10px;
      @media screen and (max-width: 1024px) {
        font-size: 28px;
      }
    }
    &-description{
      font-size: 17px;
      font-weight: 500;
      color: var(--text-color-secondary);
      margin-bottom: 35px;
      text-align: center;
      @media screen and (max-width: 1024px) {
        font-size: 14px;
      }
    }
    &-oauth{
      width: 100%;
      margin-bottom: 16px;
      display: flex;
      justify-content: center;
      &-button{
        width: 100%;
        &:not(:last-child){
          margin-right: 10px;
        }
      }
      &-separator{
        margin-bottom: 16px;
        font-size: 14px;
        font-weight: 500;
        color: var(--text-color-secondary);
      }
      @media screen and (max-width: 1024px){
        max-width: 325px;
      }
    }
    &-form{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 45px;
      &-input{
        margin-bottom: 15px;
        &:not(:first-child){
          margin-bottom: 35px;
        }
      }
      &-submit{
        width: 100%;
        margin-bottom: 15px;
        &-loader{
          width: 100% !important;
          & > div {
            background-color: #fff;
          }
        }
      }
      &-forgot-password{
        font-size: 13px;
        font-weight: 600;
        color: var(--text-color-secondary);
        text-decoration: none;
        cursor: pointer;
        & > strong{
          font-weight: 900;
        }
      }
    }
    &-login{
      &-holder{
        width: 100%;
        text-align: center;
        font-size: 15px;
        font-weight: 600;
        color: var(--text-color-secondary);
      }
      &-link{
        color: var(--blue-color);
        cursor: pointer;
      }
    }
  }
}