.button-component {
  transition: var(--transition-simple);
  cursor: pointer;
  
  @media screen and (min-width: 1025px){
    &:hover{
      transform: scale(1.03);
      filter: brightness(1.08);
    }
    &:active{
      transform: scale(0.95);
      filter: brightness(0.7);
    }
  }

  &.primary {
    padding: 0 70px;
    border-radius: 31px;
    height: 62px;
    background-color: var(--blue-color);
    color: var(--text-color-white);
    font-size: 19px;
    font-weight: bold;
  }

  &.ai {
    padding: 0 70px;
    border-radius: 31px;
    height: 62px;
    color: var(--text-color-white);
    font-size: 19px;
    font-weight: bold;
    box-shadow: 0 3px 18px 0 rgba(59, 25, 187, 0.3);
    background-image: linear-gradient(to bottom, #7b3dfc, #532ce7), linear-gradient(to bottom, rgba(183, 157, 253, 0.21), #8164fb);
    border: 2px solid #8164fb;
  }

  &.csv-export{
    height: 38px;
    border-radius: 7px;
    background-color: #dee4e9;
    padding: 0 11px;
    font-size: 15px;
    font-weight: 600;
    color: #2d3748;
    transition: var(--transition-simple);
    cursor: pointer;
    white-space: nowrap;

    &:hover{
      transform: scale(1.05);
      filter: brightness(1);
    }
    &:active{
      transform: scale(0.93);
      filter: brightness(0.7);
    }
  }

  &-icon{
    &.csv-export{
      margin-right: 7px;
      padding-top: 1px;
    }
  }

  &.phone{
    background-color: var(--button-phone-color);
    height: 60px;
    border-radius: 10px;
    box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #e3e3e3;
    color: var(--text-color-white);
    font-size: 17px;
    font-weight: bold;
    white-space: nowrap;

    @media screen and (max-width: 1024px){
      border: none;
    }
  }

  &.copy-link{
    background-color: var(--button-copy-link-color);
    height: 40px;
    border-radius: 8px;
    box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #e3e3e3;
    color: var(--text-color-white);
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1024px){
      border: none;
    }

    &:hover{
      transform: scale(1.07);
      filter: brightness(1);
    }

    &:active{
      transform: scale(0.93);
      filter: brightness(0.7);
    }
  }

  &.print{
    padding: 0 40px;
    background-color: var(--button-print-color);
    height: 60px;
    border-radius: 10px;
    box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #e3e3e3;
    color: var(--text-color-white);
    font-size: 17px;
    font-weight: bold;
    @media screen and (max-width: 1024px){
      border: none;
    }
  }

  &.back{
    background-color: var(--button-back-color);
    color: var(--text-color-primary);
    padding: 0 16px;
    height: 42px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;

    &:hover{
      filter: brightness(1);
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
