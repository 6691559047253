.onboarding-four-page{
  &-container{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--background-color);
    padding: 35px;

    @media screen and (max-width: 1024px) {
      justify-content: flex-start;
      padding: 25px 0px 0px;
      height: auto;
    }

    &.ai-background{
      background-image: radial-gradient(circle at 50% 70%, #6236ff, #091d68 98%);
    }

    &-ai-background-stars{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      opacity: 0;
      transition: var(--transition-simple);

      &.loaded{
        opacity: 1;
      }
    }
  }
  &-content{
    width: 100%;
    max-width: 1200px;
    box-shadow: 0 15px 40px 0 rgba(0, 0, 0, 0.06);
    background-color: var(--foreground-background);
    border: var(--foreground-border);
    border-radius: 20px;
    padding: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 1024px){
      flex-direction: column-reverse;
      border: none;
      max-width: 606px;
      padding: 0;
    }

    &-left{
      max-width: 450px;
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 30px 35px 30px 30px;

      @media screen and (max-width: 1024px){
        padding: 25px 25px 50px;
        max-width: 100%;
      }

      &-title{
        font-size: 35px;
        font-weight: 800;
        color: var(--text-color-primary);
        margin-bottom: 10px;

        @media screen and (max-width: 1024px){
          margin-bottom: 8px;
          font-size: 24px;
          font-weight: bold;
          max-width: 355px;
         }
      }
      &-description{
        font-size: 17px;
        font-weight: 500;
        color: var(--text-color-secondary);
        margin-bottom: 35px;

        @media screen and (max-width: 1024px){
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 22px;
          max-width: 355px;
         }
      }
    }

    &-right{
      display: flex;
      max-width: 606px;
      min-width: 606px;
      background-color: #EBEFF2;
      border-radius: 15px;
      height: 100%;
      flex-direction: column;
      align-items: center;
      min-height: 573px;

      @media screen and (max-width: 1024px){
       border-bottom-left-radius: 0;
       border-bottom-right-radius: 0;
       min-height: auto;
       max-width: 606px;
       min-width: 100%;
       width: 100%;
      }

      &-label{
        padding: 35px;
        font-size: 19px;
        font-weight: bold;
        color: var(--text-color-primary);
        display: flex;
        align-items: center;
        width: 100%;

        @media screen and (max-width: 1024px){
          padding: 25px;
        }

        &-info{
          margin-left: 8.5px;
          margin-top: 1px;
        }
      }

      &-items{
        display: flex;
        height: 100%;
        width: 100%;
        padding-bottom: 45px;
        overflow: hidden;

        @media screen and (max-width: 1024px){
          padding-bottom: 25px;
         }
        
        &-holder{
          min-width: 100%;
          height: 100%;
          display: flex;
        }
      }

      &-item{
        min-width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 386px;

        @media screen and (max-width: 1024px){
          min-height: 342px;
        }
      }

      &-nav{
        padding: 0 20px;
        min-height: 45px;
        border-radius: 22.5px;
        background-color: #fff;
        display: flex;
        align-items: center;
        margin-bottom: 25px;
        min-width: 320px;
        max-width: 320px;
        justify-content: space-between;

        @media screen and (max-width: 1024px){
          min-width: 300px;
          max-width: 300px;
        }

        &-item{
          display: flex;
          align-items: center;
          font-size: 15px;
          font-weight: 600;
          color: var(--text-color-secondary);
          transition: var(--transition-simple);
          cursor: pointer;
          height: 100%;
          user-select: none;
          padding: 9px 0;

          &-icon{
            margin-right: 7px;

            & .color-me {
              fill: var(--text-color-secondary)
            }

            & .stroke-me {
              stroke: var(--text-color-secondary)
            }
          }

          &.active{
            color: var(--blue-color);

            & .color-me {
              fill: var(--blue-color)
            }

            & .stroke-me {
              stroke: var(--blue-color)
            }
          }
        }
      }
    }

    &-form{
      display: flex;
      flex-direction: column;

      &-input{
        &-logo{
          margin-bottom: 15px;
        }
        &-color{
          margin-bottom: 45px;

          & .color-input-holder{
            border-width: 2px;
          }
        }
      }

      &-actions{
        display: flex;
        width: 100%;
        align-items: center;

        @media screen and (max-width: 1024px){
          flex-direction: column;
         }
      }

      &-submit{
        padding: 0 35px !important;
        margin-right: 40px;

        @media screen and (max-width: 1024px){
          margin-right: 0;
          margin-bottom: 5px;
          width: 100%;
         }
      }

      &-skip{
        font-size: 19px;
        font-weight: bold;
        color: var(--text-color-secondary);
        line-height: 19px;
        height: 20px;
        background-color: transparent;
        cursor: pointer;

        @media screen and (max-width: 1024px){
          width: 100%;
          height: 63px;
        }
      }
    }
  }
}