.popup-component{
  &-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
  }
  &-background{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background-color: rgba(0, 0, 0, 0.8);
  }
  &-content{
    will-change: opacity, transform;
    width: fit-content;
    max-width: 100%;
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    max-height: 100%;
    padding: 20px;
    
    @media screen and (max-width: 1024px){
      max-height: 100%;
      overflow: scroll;
      position: relative;
    }
    
    &::-webkit-scrollbar {
      display: none;
    }    
  }
}