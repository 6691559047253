.container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center safe;
  align-items: center;
  background-image: radial-gradient(circle at 50% 70%, #6236ff, #091d68 98%);
  padding: 35px;
  overflow: hidden;
  @media screen and (max-width: 1024px) {
    justify-content: flex-end;
    padding: 25px 0px 0px;
    height: 100%;
  }
  &.previewContainer{
    @media screen and (max-width: 1024px){
      padding-top: 0;
    }
  }
}
.backgroundContainer{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  transition: var(--transition-simple);

  &.loaded{
    opacity: 1;
  }
}
.content{
  width: 100%;
  max-width: calc(507px);
  box-shadow: 0 5px 41px 0 rgba(0, 0, 0, 0.04);
  background-color: var(--foreground-background);
  border: var(--foreground-border);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  @media screen and (max-width: 1024px) {
    box-shadow: none;
    padding: 0;
    max-width: 507px;
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    height: 100%;
    max-height: calc(100% - 60px);
  }
  &.previewContent{
    max-width: 1100px;
    height: 100%;

    @media screen and (max-width: 1024px){
      max-height: 100%;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border: none;
    }
  }
}
.top{
  height: 222px;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 45px;
  @media screen and (max-width: 1024px) {
    height: 188px;
    min-height: 188px;
    margin-bottom: 15px;
  }
  &.previewTop{
    margin-bottom: 0;
    padding: 31px 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
    @media screen and (max-width: 1024px) {
      position: fixed;
      bottom: 0;
      left: 0;
      background-color: #fff;
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      box-shadow: 0 2px 54px 0 rgba(0, 0, 0, 0.2);
      flex-direction: column;
      padding: 15px 25px 25px;
      z-index: 2;
    }
  }
}
.topLeft{
  display: flex;
  flex-direction: column;
}
.topLeftTitle{
  font-size: 20px;
  font-weight: bold;
  color: #1e2532;
  margin-bottom: 5px;
  @media screen and (max-width: 1024px) {
    font-size: 15px;
    font-weight: bold;
    text-align: center;
  }
}
.topLeftDescription{
  font-size: 16px;
  font-weight: 500;
  color: #6e8397;
  @media screen and (max-width: 1024px) {
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 15px;
  }
}
.topButton{
  padding: 0 35px !important;
  display: flex;
  align-items: center;
}
.topButtonIcon{
  margin-left: 5px;
  margin-top: 3px;
  & path:nth-child(2){
    fill: #fff;
  }
}
.background{
  position: absolute;
  top: 0px;
  left: 0px;
}
.icon{
  left: calc(50% - 69.5px);
  top: 45px;
  position: absolute;
}
.bottom{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 45px 45px;
  &.previewBottom{
    padding: 0;
    height: 100%;
    @media screen and (max-width: 1024px) {
      height: calc(100% - 156px + 25px);
      padding-bottom: 25px;
      background-color: #fff;
      position: relative;
      z-index: 1;
    }
  }
  @media screen and (max-width: 1024px) {
    padding: 0 25px 45px;
    height: 100%;
  }
}
.iframe{
  width: 100%;
  height: 100%;
}
.title{
  font-size: 32px;
  font-weight: bold;
  color: #1e2532;
  margin-bottom: 20px;
  @media screen and (max-width: 1024px) {
    font-size: 24px;
    font-weight: bold;
  }
}
.titleLoading{
  font-size: 32px;
  font-weight: bold;
  color: #1e2532;
  margin-bottom: 20px;
  text-align: center;
}
.spinner{
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    background-color: var(--ai-color) !important;
  }
}