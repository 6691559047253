.verify-page{
  &-container{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--background-color);
    padding: 35px;

    @media screen and (max-width: 1024px) {
      justify-content: flex-start;
      padding: 25px 10px 150px;
      height: auto;
    }
  }
  &-content{
    width: 100%;
    max-width: 700px;
    box-shadow: 0 15px 40px 0 rgba(0, 0, 0, 0.06);
    background-color: var(--foreground-background);
    border: var(--foreground-border);
    border-radius: 20px;
    padding: 55px 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 1024px) {
      box-shadow: none;
      padding: 30px 20px 45px;
    }
  }
  &-graphic{
    width: 100%;
    height: 228px;
    position: relative;
    margin-bottom: 45px;
    &-background{
      position: absolute;
      width: 316px;
      height: auto;
      left: calc(50% - min(158px, 50%));
      bottom: 0;
      @media screen and (max-width: 1024px) {
        width: 100%;
        max-width: 316px;
      }
    }
    &-mailbox{
      position: absolute;
      left: calc(50% - 85px);
      bottom: 0;
      width: 200px;
      height: auto;
    }
  }
  &-title{
    font-size: 35px;
    font-weight: 800;
    color: var(--text-color-primary);
    margin-bottom: 10px;
    text-align: center;
  }
  &-description{
    font-size: 17px;
    font-weight: 500;
    text-align: center;
    color: var(--text-color-secondary);
    margin-bottom: 45px;
    & > strong {
      color: var(--text-color-secondary);
    }
  }
  &-resend{
    padding: 0 30px !important;
    margin-bottom: 15px;
  }
  &-label{
    font-size: 15px;
    font-weight: 600;
    color: var(--text-color-secondary);
    text-align: center;
    cursor: pointer;
  }
}