.template-ai-card-component{
  &-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-image: linear-gradient(to bottom, #091d68 75%, #6236ff 100%);
    box-shadow: 0 5px 41px 0 rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    overflow: hidden;
    @media screen and (max-width: 1024px){
      flex-direction: column;
      max-width: 575px;
      margin-top: 45px;
    }
    @media screen and (max-width: 574px){
      margin-top: 0px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  &-left{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 75px 45px 75px 75px;
    max-width: calc(500px + 120px);
    @media screen and (max-width: 1024px){
      padding: 45px 25px 0px 25px;
    }
  }
  &-right{
    width: 100%;
    position: relative;
    min-width: 370px;
    max-width: 370px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 1024px){
      min-width: 100%;
      max-width: 100%;
      justify-content: flex-end;
      position: initial;
    }
  }
  &-label{
    font-size: 21px;
    font-weight: bold;
    color: #fff;
    opacity: 0.83;
    margin-bottom: 6px;
    font-family: Caveat;
  }
  &-title{
    font-size: 36px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 8px;
    max-width: 459px;
    @media screen and (max-width: 1024px){
      font-size: 27px;
      font-weight: bold;
    }
  }
  &-description{
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    line-height: 1.44;
    opacity: 0.8;
    margin-bottom: 72px;
    @media screen and (max-width: 1024px){
      margin-bottom: 0;
      max-width: 305px;
      font-size: 16px;
      font-weight: 600;
    }
  }
  &-actions{
    position: relative;
    @media screen and (max-width: 1024px){
      position: initial;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 95px 35px 45px;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #6236ff 60%);
      display: flex;
      justify-content: center;
    }
  }
  &-button{
    width: fit-content;
    width: -moz-fit-content;
    border-radius: 31.5px;
    height: 63px;
    display: flex;
    align-items: center;
    padding: 0 25px;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    box-shadow: 0 3px 8px 0 rgba(59, 25, 187, 0.3), 0 12px 65px 0 rgba(0, 0, 0, 0.4);
    background-image: linear-gradient(to bottom, #7b3dfc, #532ce7), linear-gradient(to bottom, rgba(183, 157, 253, 0.21), #8164fb);
    transition: var(--transition-simple);
    border: 2px solid #8164fb;
    cursor: pointer;
    &:hover{
      transform: scale(1.08);
      filter: brightness(1.3);
    }
    &:active{
      transform: scale(0.95);
      filter: brightness(0.5);
    }
    & > svg {
      margin-right: 9px;
    }
    @media screen and (max-width: 1024px){
      width: 100%;
      justify-content: center;
    }
    &-label{
      position: absolute;
      bottom: -15px;
      left: 255px;
      @media screen and (max-width: 1024px){
        display: none;
      }
    }
  }
  &-image{
    width: 444px;
    @media screen and (max-width: 1024px){
      width: 400px;
      margin-right: -35px;
    }
    &-background{
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      z-index: -1;
      @media screen and (max-width: 1024px){
        display: none;
      }
    }
  }
}