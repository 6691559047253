.arrow-button-component{
  &-container{
    min-width: 51px;
    max-width: 51px;
    min-height: 51px;
    max-height: 51px;
    border-radius: 25.5px;
    background-color: #fff;
    transition: var(--transition-simple);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &.disabled{
      opacity: 0.5
    }

    &:hover{
      transform: scale(1.05);
    }

    &:active{
      transform: scale(0.9);
      filter: brightness(0.7);
    }
  }
  &-icon{
    height: 33px;
    width: 26px;

    &.left{
      transform: rotate(180deg)
    }
  }
}