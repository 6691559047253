.settings-page{
  &-container{
    display: flex;
    flex-direction: column;
    max-width: 1000px;
    margin: 0 auto;
    padding-bottom: 140px;
    opacity: 0;
    animation-name: fadeIn;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
    @media screen and (max-width: 1024px){
      padding: 0 20px;
    }
  }
  &-toggler{
    margin-bottom: 25px;
  }
  &-title{
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 5px;
    color: var(--text-color-primary);
  }
  &-subtitle{
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 25px;
    color: var(--text-color-secondary);
    &-link{
      color: var(--blue-color);
      text-decoration: none;
      &.with-margin{
        display: block;
        margin-top: 25px;
      }
    }
  }
  &-block{
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid var(--lead-list-border-color);
    background-color: var(--white-color);
    padding: 25px 20px;
    &:not(:last-child){
      margin-bottom: 25px;
    }
    &-code{
      width: 100%;
      position: relative;
      &-copy{
        position: absolute;
        top: 0;
        right: 0;
      }
      &-head{
        &-button{
          color: var(--blue-color);
          background-color: transparent;
          cursor: pointer;
          font-size: 16px;
          padding: 5px 0;
          margin-top: 15px;
        }
        &-label{
        display: block;
          margin-top: 35px;
          font-size: 16px;
          margin-bottom: 5px;
        }
      }
    }
    &-top{
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;
      &-left{
        display: flex;
        flex-direction: column;
        &-title{
          font-size: 21px;
          font-weight: bold;
          margin-bottom: 5px;
          color: var(--text-color-primary);
        }
        &-subtitle{
          font-size: 15px;
          font-weight: 500;
          color: var(--text-color-secondary);
        }
      }
    }
    &-email{
      &-holder{
        display: flex;
        justify-content: space-between;
        margin-bottom: 35px;
        width: 100%;
        flex-direction: column;
      }
      & > .input-component-container{
        width: 100%;
        margin-right: 25px;
        & > input{
          width: 100%;
        }
        @media screen and (max-width: 1024px){
          margin-bottom: 4px;
          margin-right: 0;
        }
      }
      display: flex;
      justify-content: space-between;
      width: 100%;

      @media screen and (max-width: 1024px){
        flex-direction: column;
      }

      &:not(:last-child){
        margin-bottom: 15px;
        @media screen and (max-width: 1024px){
          margin-bottom: 25px;
        }
      }
    }
    &-actions{
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 1024px){
        flex-direction: column;
      }
      
      &-right-holder{
        display: flex;
        align-items: flex-end;
        & > *:not(:last-child) {
          margin-right: 10px;
        }
      }

      & > .input-component-container{
        width: 100%;
        margin-right: 25px;
        & > input{
          width: 100%;
        }
        @media screen and (max-width: 1024px){
          margin-bottom: 25px;
          margin-right: 0;
        }
      }
      &-select{
        width: 100%;
        margin-right: 25px;
        @media screen and (max-width: 1024px){
          margin-bottom: 25px;
          margin-right: 0;
        }
      }
      &-submit{
        height: 53px !important;
        white-space: nowrap;
        background-color: var(--blue-color) !important;

        &.save{
          margin-top: 75px;
          background-color: var(--button-phone-color) !important;
        }
        
        &-label{
          font-weight: 600;
          color: var(--button-phone-color);
        }
      }
      &-secondary{
        height: 53px !important;
        white-space: nowrap;
        background-color: var(--button-back-color) !important;
        color: var(--text-color-primary) !important;
        font-weight: 400 !important;
      }
    }
  }
}