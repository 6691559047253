.container{
  padding: 30px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.06), 0 1px 5px 0 rgba(0, 0, 0, 0.09);
  min-width: 100%;
  max-width: 720px;
  overflow: hidden;
  min-height: 965px;
}
.content{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.backButton{
  width: 43px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #edf2f6;
  cursor: pointer;
  transition: var(--transition-simple);
  &:hover{
    transform: scale(1.1);
  }
  &:active{
    transform: scale(0.9);
    filter: brightness(0.8);
  }
}
.backButtonIcon{
  width: 28px;
}
.header{
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 30px 0 32px;
  @media screen and (max-width: 1024px){
    flex-direction: column-reverse;
  }
  max-width: 660px;
}
.headerLeftIntegration{
  display: flex;
  align-items: center;
}
.headerLeft{
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1024px){
    margin-top: 15px;
  }
}
.headerTitle{
  font-size: 24px;
  font-weight: 800;
  color: #171e29;
  margin-bottom: 4px;
}
.headerDesc{
  font-size: 15px;
  font-weight: 600;
  color: #718096;
}
.banner{
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 15px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.06), 0 1px 5px 0 rgba(0, 0, 0, 0.09);
  background-image: linear-gradient(58deg, #fff 67%, #d4ecff 100%);
  margin-bottom: 15px;
  @media screen and (max-width: 1024px){
    flex-direction: column-reverse;
  } 
}
.bannerLeft{
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 355px;
  padding: 25px;
}
.bannerLabel{
  font-size: 12px;
  font-weight: 800;
  color: #0091ff;
  margin-bottom: 8px;
}
.bannerTitle{
  font-size: 16px;
  font-weight: bold;
  color: #171e29;
  margin-bottom: 4px;
}
.bannerDesc{
  font-size: 13px;
  font-weight: 600;
  color: #718096;
}
.bannerRight{
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 170px;
}
.bannerRightImage{
  height: 360px;
  width: auto;
  bottom: 35px;
  right: 30px;
  position: absolute;
}
.integrations{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  max-width: 660px;
  margin-bottom: 25px;
  @media screen and (max-width: 1024px){
    flex-direction: column;
  }
}
.integration{
  width: calc(50% - 10px);
  border-radius: 15px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.06), 0 1px 5px 0 rgba(0, 0, 0, 0.09);
  background-color: #fff;
  margin-bottom: 20px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: var(--transition-simple);
  &:hover{
    transform: scale(1.05);
  }
  &:active{
    transform: scale(0.95);
    filter: brightness(0.8);
  }
  @media screen and (max-width: 1024px){
    width: 100%;
  }
  @media screen and (max-width: 470px){
    flex-direction: column;
  }
}
.integrationLeft{
  display: flex;
  @media screen and (max-width: 470px){
    margin-bottom: 15px;
    width: 100%;
  }
}
.integrationIcon{
  max-width: 50px;
  max-height: 50px;
  min-width: 50px;
  min-height: 50px;
  object-fit: cover;
  border-radius: 10px;
  overflow: hidden;
  margin-right: 12px;
}
.integrationContent{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 15px;
}
.integrationName{
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 1px;
  color: #171e29;
}
.integrationDesc{
  font-size: 13px;
  font-weight: 600;
  color: #718096;
}
.integrationButton{
  height: 32px;
  background-color: #dee4e9;
  padding: 0 10px;
  border-radius: 8px;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  color: #2d3748;
  @media screen and (max-width: 470px){
    width: 100%;
  }
}
.breaker{
  width: 100%;
  height: 1px;
  background-color: #d8d8d8;
  margin-bottom: 45px;
}
.help{
  width: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}
.helpTitle{
  font-size: 19px;
  font-weight: bold;
  color: #171e29;
  margin-bottom: 4px;
}
.helpDesc{
  font-size: 15px;
  font-weight: 600;
  max-width: 400px;
  margin-bottom: 15px;
  color: #718096;
}
.codeBlock{
  scrollbar-color: rebeccapurple green;
}
.steps{
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  & > a{
    font-size: 16px;
    font-weight: 500;
    color: var(--blue-color);
    line-height: 1.5;
    margin-bottom: 25px;
  }
  & > h3{
    font-size: 23px;
    font-weight: bold;
    margin-bottom: 12px;
    line-height: 1.04;
    color: #252f3f;
    margin-top: 12px;
  }
  & > p{
    font-size: 16px;
    font-weight: 500;
    color: #738196;
    line-height: 1.5;
    margin-bottom: 25px;
  }
}
.approveIconColor{
  & path:nth-child(2) {
    stroke: #2c93ff;
  }
}