.tapform-widget-component{
  &-container{
    width: 345px;
    border-radius: 30px;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.3);
    background-image: linear-gradient(to top, #000, rgba(0, 0, 0, 0.91));
    display: flex;
    flex-direction: column;
    padding: 20px 20px 90px;
    position: relative;
  }
  &-top{
    display: flex;
    width: 100%;

    &-logo{
      min-width: 45px;
      max-width: 45px;
      min-height: 45px;
      max-height: 45px;
      object-fit: contain;
      border-radius: 50%;
      margin-right: 15px;
      background-color: #fff;
    }

    &-right{
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &-label{
      font-size: 14px;
      color: #BEBEBE;
      font-weight: 500;
    }

    &-title{
      font-size: 19px;
      font-weight: 600;
      color: #fff;
    }
  }

  &-button{
    width: 306px;
    position: fixed;
    bottom: 35px;
    left: calc(50% - 153px);
    border-radius: 32px;
    height: 54px;
    font-size: 17px;
    font-weight: 600;
    color: #fff;
    z-index: 999999998;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: var(--transition-simple);
    position: absolute;
    bottom: 20px;
    left: 20px;
    box-shadow: var(--tf-button-shadow-i);
    -webkit-animation: buttonPulse 1.5s;
    animation: buttonPulse 1.5s;
    animation-iteration-count: infinite;
  }
}

@keyframes buttonPulse{
  0% {
    transform: scale(.95);
  }
  70% {
    transform: scale(1);
    box-shadow: var(--tf-button-shadow-s)
  }
    100% {
    transform: scale(.95);
    box-shadow: var(--tf-button-shadow-e)
  }
}

@-webkit-keyframes buttonPulse{
  0% {
    transform: scale(.95);
  }
  70% {
    transform: scale(1);
    box-shadow: var(--tf-button-shadow-s);
  }
    100% {
    transform: scale(.95);
    box-shadow: var(--tf-button-shadow-e)
  }
}