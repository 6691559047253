@keyframes fadeIn {
  0%{
    opacity: 0;
    transform: translateY(-100%);
  }
  100%{
    opacity: 1;
    transform: translateY(0%);
  }
}
.container{
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 34px;
  opacity: 0;
  @media screen and (max-width: 1024px){
    display: none;
  }
  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-fill-mode: forwards;
}
.header{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;
}
.headerLeft{
  display: flex;
  flex-direction: column;
  max-width: 50%;
}
.headerTitle{
  font-size: 26px;
  font-weight: bold;
  color: var(--text-color-primary);
  margin-bottom: 2px;
}
.headerDesc{
  font-size: 16px;
  font-weight: 500;
  color: var(--text-color-secondary);
}
.headerRight{
  display: flex;
  align-items: center;
  max-width: 50%;
}
.headerDate{
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 7px;
  border: solid 1px #e3e3e3;
  background-color: #fff;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.03);
  font-size: 15px;
  font-weight: 600;
  color: #2d3748;
  padding: 0 15px;
  & > svg {
    width: 16px;
    height: auto;
    margin-right: 10px;
  }
}
.holder{
  width: 100%;
  justify-content: space-between;
  display: flex;
}
.left{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 678px;
  margin-right: 10px;
}
.card{
  width: calc(50% - 5px);
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  border: solid 1px #e3e3e3;
  background-color: #fff;
  border-radius: 10px;
  &:nth-child(1),&:nth-child(2){
    margin-bottom: 10px;
  }
}
.cardValue{
  font-size: 35px;
  font-weight: bold;
  color: #1c85e8;
  margin-bottom: 11px;
}
.cardTitle{
  font-size: 17px;
  font-weight: bold;
  color: #000;
  margin-bottom: 2px;
}
.cardDesc{
  font-size: 13px;
  font-weight: 600;
  opacity: 0.5;
  color: #000;
}
.cardIcon{
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 25px;
}
.right{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  min-width: 321px;
  max-width: 321px;
  border: solid 1px #e3e3e3;
  background-image: linear-gradient(to bottom, #fff, #fff);
  border-radius: 10px;
}
.rightIcon{
  min-width: 80px;
  max-width: 80px;
  min-height: 80px;
  max-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  font-size: 40px;
  background-color: #edf2f6;
  margin-bottom: 20px;
}
.rightTitle{
  font-size: 17px;
  font-weight: bold;
  color: #000;
  margin-bottom: 2px;
  text-align: center;
}
.rightDescription{
  font-size: 14px;
  font-weight: 500;
  color: #000;
  text-align: center;
  margin-bottom: 16px;
  opacity: 0.5;
}
.submitFeedback{
  border-radius: 7px;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.03);
  border: solid 1px #e3e3e3;
  background-color: #fff;
  padding: 0 15px;
  height: 36px;
  color: #2d3748;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: var(--transition-simple);
  &:hover{
    transform: scale(1.08);
  }
  &:active{
    transform: scale(0.95);
    filter: brightness(0.7);
  }
}

