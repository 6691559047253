.simple-select-component{
  font-size: 16px;
  font-weight: 500;
  color: var(--input-text-color);
  cursor: pointer;
  border: solid 2px var(--input-border-color-inactive);
  transition: 0.25s ease;
  border-radius: var(--input-border-radius);
  padding: 15px 60px 16px 16px;

  &:hover {
    border-color: var(--input-border-color-hover);
  }
}