.single-lead-page{
  &-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    opacity: 0;
    animation-name: fadeIn;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
    padding-bottom: 140px;
    @media screen and (max-width: 1024px){
      padding: 0 20px 140px;
    }
  }
  &-mobile-actions{
    display: flex;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 20px;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
    padding-top: 90px;
  }
  &-info{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 25px;
    &-left{
      width: 100%;
      max-width: 660px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      &-back{
        margin-bottom: 15px;
        width: fit-content;
        width: -moz-fit-content;
      }
      &-name{
        font-size: 26px;
        font-weight: bold;
        color: var(--text-color-primary);
        margin-bottom: 5px;
      }
      &-date{
        font-size: 16px;
        font-weight: 500;
        color: var(--text-color-secondary);
      }
    }
    &-right{
      min-width: 326px;
      max-width: 326px;
      display: flex;
      height: fit-content;
      height: -moz-fit-content;
      flex-direction: column;
      justify-content: flex-end;
      @media screen and (max-width: 1024px){
        display: none;
        min-width: 0;
        max-width: 0;
      }
      &-actions{
        display: flex;
        width: 100%;
        justify-content: flex-end;

        &-phone{
          width: 100%;
          margin-right: 15px;
        }
      }
    }
  }
  &-holder{
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 1024px){
      flex-direction: column-reverse;
    }
    &-left{
      width: 100%;
      max-width: 660px;
      height: fit-content;
      height: -moz-fit-content;
      @media screen and (max-width: 1024px){
        min-width: 100%;
        margin-bottom: 20px;
      }
    }
    &-right{
      min-width: 326px;
      max-width: 326px;
      height: fit-content;
      height: -moz-fit-content;
      @media screen and (min-width: 1025px){
        position: sticky;
        top: 100px;
      }
      @media screen and (max-width: 1024px){
        min-width: 100%;
      }
      &-menu-toggler{
        margin-bottom: 15px;
      }
      &-notes{
        &-button{
          width: 100%;
          margin-bottom: 24px;
        }
        &-title{
          padding: 0 15px;
          margin-bottom: 8px;
          display: flex;
          color: var(--text-color-primary);
          font-size: 18px;
          font-weight: 700;
          @media screen and (max-width: 1024px){
            padding: 0;
          }
        }
        &-note{
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 16px;
          border-radius: var(--input-border-radius);
          background-color: #fff;
          border: 1px solid var(--input-border-color-inactive);
          &-author{
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            margin-bottom: 8px;
            &-name{
              font-size: 15px;
              color: var(--text-color-secondary);
              margin-bottom: 10px;
            }
          }
          &-date{
            font-size: 15px;
            color: var(--text-color-secondary);
            margin-bottom: 10px;
          }
          &-text{
            font-size: 16px;
            font-weight: 500;
            color: var(--input-text-color);
            white-space: pre-wrap;
          }
          &:not(:last-child){
            margin-bottom: 15px;
          }
          @media screen and (max-width: 1024px){
            &:last-child{
              margin-bottom: 45px;
            }
          }
        }
      }
      &-info{
        margin-bottom: 15px;
      }
      &-utm{
        margin-bottom: 15px;
      }
    }
  }
}