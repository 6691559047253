.tapform-popup-component{
  &-container{
    width: 281px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 25px;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
    padding: 10px 30px 30px;
    background-color: #fff;
  }
  &-top-drag{
    height: 5px;
    width: 65px;
    border-radius: 2.5px;
    background-color: #ebeff2;
    margin-bottom: 20px;
  }
  &-image{
    height: 44px;
    width: 125px;
    object-fit: contain;
    margin-bottom: 20px;
  }
  &-label{
    font-size: 10px;
    font-weight: 800;
    margin-bottom: 3px;
    text-align: center;
    text-transform: uppercase;
  }
  &-title{
    font-size: 18px;
    font-weight: 800;
    text-align: center;
    color: #252f3f;
    margin-bottom: 35px;
  }
  &-button{
    display: flex;
    align-items: center;
    padding: 0 7px 0 25px;
    height: 55px;
    border-radius: 27.5px;
    font-size: 15px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 30px;
    &-icon{
      min-width: 41px;
      max-width: 41px;
      min-height: 41px;
      max-height: 41px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-left: 16px;
      &-svg path {
        fill: var(--brand-color);
      }
    }
  }
  &-disclaimer{
    font-size: 8px;
    font-weight: 600;
    line-height: 1.38;
    color: #0d3051;
    text-align: center;
    opacity: 0.5;
  }
}