.lead-answers-component{
  &-container{
    padding-top: 15px;
    display: flex;
    width: 100%;
    flex-direction: column;
    border-radius: 10px;
    margin-right: 15px;
    background-color: var(--white-color);
    border: 1px solid var(--lead-list-border-color);
    box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.05);
    margin-bottom: 10px;
  }
  &-answer{
    padding: 20px 35px;
    display: flex;
    flex-direction: column;
    &:not(:last-child){
      border-bottom: 1px solid var(--lead-list-border-color);
    }
    &-order{
      font-size: 15px;
      color: var(--text-color-secondary);
      margin-bottom: 10px;
    }
    &-question{
      font-size: 19px;
      font-weight: 600;
      color: var(--text-color-primary);
      margin-bottom: 10px;
    }
    &-answer{
      display: flex;
      align-items: center;
      background-color: var(--answer-background-color);
      color: var(--answer-text-color);
      padding: 5px 7px;
      border-radius: 8px;
      margin-bottom: 20px;
      width: fit-content;
      width: -moz-fit-content;

      & > svg {
        margin-right: 6px;
      }
    }
  }
  &-additional{
    &-title{
      padding: 20px 35px 0;
      margin-bottom: 10px;
      font-size: 19px;
      font-weight: 600;
      color: var(--text-color-primary);
    }
    &-note{
      font-size: 15px;
      font-weight: normal;
      padding: 0px 35px 35px;
      color: var(--text-color-secondary);
    }
  }
}