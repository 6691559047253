.forminput-component {
  &-container {
    position: relative;
    width: 100%;
  }
  &-input {
    padding: 29px 60px 11px 16px;
    font-size: 16px;
    font-weight: 500;
    color: var(--input-text-color);
    width: 100%;
    border-radius: var(--input-border-radius);
    border: solid 1px var(--input-border-color-inactive);
    transition: var(--transition-simple);

    &::placeholder{
      transition: var(--transition-simple);
      opacity: 0;
    }

    &.error {
      border-color: var(--input-border-color-error) !important;
    }

    &:hover {
      border-color: var(--input-border-color-hover);
    }

    &:focus {
      border-color: var(--input-border-color-focus);
      box-shadow: var(--input-shadow-color-focus);

      &.ai{
        border-color: var(--input-border-color-ai-focus);
        box-shadow: var(--input-shadow-color-ai-focus);
      }

      &::placeholder{
        opacity: 1;
      }

      & + .input-component-valid.active {
        opacity: 1;
      }
    }

    &:-webkit-autofill{
      &+.forminput-component-label{
        top: 9px;
        font-size: 14px;
      }
    }
  }
  &-label{
    position: absolute;
    font-size: 16px;
    font-weight: 500;
    left: 15px;
    top: 22px;
    transition: var(--transition-simple);
    color: var(--input-placeholder-color);
    cursor: text;

    &.focused{
      top: 9px;
      font-size: 14px;
      color: var(--input-border-color-focus);

      &.ai{
        color: var(--input-border-color-ai-focus);
      }
    }

    &.error{
      color: var(--input-border-color-error);
    }
    
    &.has-value{
      top: 9px;
      font-size: 14px;
    }
  }
  &-valid {
    position: absolute;
    top: 20px;
    right: 20px;
    min-width: 24px;
    min-height: 24px;
    max-width: 24px;
    max-height: 24px;
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--blue-color);
    opacity: 0;
    transition: var(--transition-simple);

    &.inactive {
      opacity: 0;
    }

    & svg {
      width: 12px;
    }
  }
}
