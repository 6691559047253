.template-card-component{
  $color-1: #0091ff;
  $color-2: #a958fc;
  $color-3: #3db62b;
  $color-4: #9d8356;
  $color-5: #ff881e;
  $color-6: #035c96;
  $color-7: #a4c230;
  $color-8: #0ebcb5;
  $color-9: #73bbd1;
  $color-10: #14c8ea;
  $color-11: #739fac;
  $animationScaleLabel: scale(1.1);
  $animationScaleTitle: scale(1.1);
  $animationScaleImage: scale(1.15);

  &-container{
    width: 100%;
    padding: 35px 55px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border-radius: 20px;
    cursor: pointer;
    overflow: hidden;

    @media screen and (max-width: 1024px){
      padding: 25px 25px 0;
    }

    &.roofing{
      background-color:  $color-1;
    }
    &.hvac{
      background-color:  $color-2;
    }
    &.landscaping{
      background-color:  $color-3;
    }
    &.x{
      background-color:  $color-4;
    }
    &.solar{
      background-color:  $color-5;
    }
    &.garage-door{
      background-color:  $color-6;
    }
    &.floor-cleaning{
      background-color:  $color-7;
    }
    &.x{
      background-color:  $color-8;
    }
    &.painting{
      background-color:  $color-9;
    }
    &.pool-services{
      background-color:  $color-10;
    }
    &.concrete{
      background-color:  $color-11;
    }
    
    &:hover{

      & .template-card-component-label {
        transform: $animationScaleLabel !important;
      }
      & .template-card-component-title {
        transform: $animationScaleTitle !important;
      }
      & > .template-card-component-image {
        transform: $animationScaleImage !important;
      }
      & > .template-card-component-button{
        transform: translateY(0);
        opacity: 1;
      }
      & > .template-card-component-overlay{
        opacity: 0.7;
      }
    }
  }
  &-overlay{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    transition: var(--transition-simple);

    &.roofing{
      background-color:  $color-1;
    }
    &.hvac{
      background-color:  $color-2;
    }
    &.landscaping{
      background-color:  $color-3;
    }
    &.x{
      background-color:  $color-4;
    }
    &.solar{
      background-color:  $color-5;
    }
    &.garage-door{
      background-color:  $color-6;
    }
    &.floor-cleaning{
      background-color:  $color-7;
    }
    &.x{
      background-color:  $color-8;
    }
    &.painting{
      background-color:  $color-9;
    }
    &.pool-services{
      background-color:  $color-10;
    }
    &.concrete{
      background-color:  $color-11;
    }
  }
  &-label{
    color: #fff;
    font-size: 14px;
    font-weight: 800;
    margin-bottom: 9px;
    text-align: center;
    transition: var(--transition-simple);
    text-transform: uppercase;
  }
  &-text-holder{
    display: flex;
    flex-direction: column;
  }
  &-title{
    color: #fff;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 34px;
    text-align: center;
    transition: var(--transition-simple);
  }
  &-image{
    width: 100%;
    padding: 13.5px 15px 0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: #00000024;
    display: flex;
    transition: var(--transition-simple);
    transform: translateY(1px);

    &-image{
      width: 100%;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }
  }
  &-button{
    position: absolute;
    bottom: 25px;
    left: calc(50% - 127px);
    z-index: 2;
    width: 254px;
    height: 52px;
    border-radius: 26px;
    background-color: #fff;
    transition: var(--transition-simple);
    transform: translateY(100%);
    opacity: 0;
    font-size: 17px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &.roofing{
      color:  $color-1;
    }
    &.hvac{
      color:  $color-2;
    }
    &.landscaping{
      color:  $color-3;
    }
    &.x{
      color:  $color-4;
    }
    &.solar{
      color:  $color-5;
    }
    &.garage-door{
      color:  $color-6;
    }
    &.floor-cleaning{
      color:  $color-7;
    }
    &.x{
      color:  $color-8;
    }
    &.painting{
      color:  $color-9;
    }
    &.pool-services{
      color:  $color-10;
    }
    &.concrete{
      color:  $color-11;
    }

    &-arrow{
      width: 18px;
      margin-left: 7px;

      &.roofing path:nth-child(2){
        fill:  $color-1;
      }
      &.hvac path:nth-child(2){
        fill:  $color-2;
      }
      &.landscaping path:nth-child(2){
        fill:  $color-3;
      }
      &.x path:nth-child(2){
        fill:  $color-4;
      }
      &.solar path:nth-child(2){
        fill:  $color-5;
      }
      &.garage-door path:nth-child(2){
        fill:  $color-6;
      }
      &.floor-cleaning path:nth-child(2){
        fill:  $color-7;
      }
      &.x path:nth-child(2){
        fill:  $color-8;
      }
      &.painting path:nth-child(2){
        fill:  $color-9;
      }
      &.pool-services path:nth-child(2){
        fill:  $color-10;
      }
      &.concrete path:nth-child(2){
        fill:  $color-11;
      }
    }
  }
}