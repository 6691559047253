.file-input{
  &-container{
    width: 100%;
    display: flex;
  }
  &-left{
    display: flex;
    flex-direction: column;
    width: 100%;

    &-status{
      margin-bottom: 2px;
      font-size: 16px;
      font-weight: 600;
      color: var(--text-color-secondary);
      text-align: left;
      transition: var(--transition-simple);
    }

    &-size{
      font-size: 16px;
      font-weight: 500;
      color: #b6c1cb;
      text-align: left;
    }
  }
  &-right{
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;

    &-status{
      min-width: 48px;
      max-width: 48px;
      min-height: 48px;
      max-height: 48px;
      border-radius: 50%;
      border: solid 1px #e9eef3;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      transition: var(--transition-simple);
    }

    &-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &-spinner{
      width: 24px;
      height: 24px;
      border-top-color: #fff;
    }

    &-icon path{
      transition: var(--transition-simple);
    }
  }
  &-holder{
    border-radius: 8px;
    background-color: #fff;
    height: 88px;
    width: 100%;
    transition: var(--transition-simple);
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%231A9ED9FF' stroke-width='3' stroke-dasharray='8%2c 12' stroke-dashoffset='6' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 8px;

    & .ant-upload-drag {
      border: 2px solid transparent !important;
      background-color: transparent !important;
      transition: var(--transition-simple);
      border-radius: 8px !important;
    }

    & .ant-upload-btn{
      display: flex !important;
      width: 100% !important;
      padding: 20px 25px 20px 20px !important;
      border: none !important;
      transition: var(--transition-simple);
      border-radius: 8px !important;
    }

    &.uploading{
      & .ant-upload-drag{
        border: 2px solid var(--blue-color) !important;
      }

      & .file-input-right-status{
        background-color: var(--blue-color);
      }

      & .ant-upload-btn{
        background-color: #EFF7FE !important;
      }
    }

    &.done {
      & .ant-upload-drag{
        border: 2px solid var(--input-border-color-inactive) !important;
      }

      background-image: none;

      &:hover {
        & .ant-upload-drag{
          border: 2px solid var(--input-border-color-hover) !important;
        }
      }

      & .file-input-right-status{
        background-color: #fff !important;
      }
    }

    &.no{
      & .file-input-right-icon path{
        fill: var(--blue-color);
      }
    }

    &.error{
      & .ant-upload-drag{
        border: 2px solid var(--input-border-color-error) !important;
      }
    }

    & .ant-upload-drag.ant-upload-drag-hover {
      border: 2px solid var(--blue-color) !important;
      
      & .ant-upload-btn{
        background-color: #EFF7FE !important;
      }

      & .file-input-left-status{
        color: var(--blue-color);
      }

      & .file-input-right-status{
        background-color: var(--blue-color);
      }

      & .file-input-right-icon path{
        fill: #fff;
      }
    }

    & .ant-upload-drag-container{
      display: flex !important;
      justify-content: space-between !important;
      align-items: center !important;
      width: 100% !important;
    }
  }
}